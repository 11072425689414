import { CURRENT_USER } from 'libs/react-query/constants';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { userApi } from 'api/fetchers';

export default function useUserQuery() {
  const {
    data,
    ...values
  } = useQuery(CURRENT_USER, userApi.getCurrentUser, {
    retry: false,
    refetchOnWindowFocus: false, // new metamask window will trigger refetch
    refetchOnMount: false,
    retryOnMount: false,
    staleTime: Infinity,
  });

  return {
    isLoggedIn: !isEmpty(data?.data),
    user: data?.data,
    ...values,
  };
}
