import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

Bugsnag.start({
  apiKey: publicRuntimeConfig?.bugsnagSecret,
  plugins: [new BugsnagPluginReact()],
  releaseStage: publicRuntimeConfig?.appEnvironment,
  enabledReleaseStages: ['production', 'staging'],
  appVersion: publicRuntimeConfig?.herokuReleaseVersion,
});

export default Bugsnag;
