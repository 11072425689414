import { number, oneOfType, string } from 'prop-types';
import { useState } from 'react';

function TelegramIcon({ size = 24 }) {
  const [isHover, setHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="36"
        height="36"
        rx="18"
        fill={isHover ? '#FCFCFC' : 'none'}
        fillOpacity="0.9"
      />
      <path
        d="M25.485 11.3517L22.9016 23.6852C22.8703 23.8344 22.8021 23.9733 22.7031 24.0893C22.6041 24.2052 22.4776 24.2944 22.3352 24.3486C22.1928 24.4029 22.039 24.4205 21.888 24.3998C21.737 24.3792 21.5936 24.3209 21.471 24.2303L17.7132 21.454L15.4365 23.6018C15.4067 23.6299 15.3716 23.6518 15.3332 23.6662C15.2949 23.6806 15.254 23.6873 15.2131 23.6857C15.1722 23.6841 15.1319 23.6744 15.0948 23.6572C15.0576 23.6399 15.0243 23.6154 14.9967 23.5851L14.9478 23.5314L15.3434 19.7031L22.4734 13.1887C22.5086 13.1565 22.5305 13.1123 22.5348 13.0648C22.5391 13.0172 22.5254 12.9698 22.4964 12.9319C22.4674 12.894 22.4253 12.8683 22.3783 12.8599C22.3313 12.8515 22.2829 12.861 22.2426 12.8866L13.1242 18.6578L9.19711 17.3389C9.09269 17.3038 9.00168 17.2372 8.93657 17.1484C8.87146 17.0595 8.83544 16.9527 8.83345 16.8426C8.83145 16.7324 8.86359 16.6244 8.92544 16.5332C8.9873 16.4421 9.07584 16.3723 9.17893 16.3335L24.5442 10.5453C24.6627 10.5007 24.791 10.4887 24.9157 10.5107C25.0405 10.5327 25.157 10.5877 25.2531 10.6702C25.3493 10.7526 25.4216 10.8593 25.4623 10.9792C25.5031 11.0991 25.511 11.2277 25.485 11.3517Z"
        fill={isHover ? '#0088CC' : '#FCFCFC'}
      />
    </svg>

  );
}

TelegramIcon.propTypes = {
  size: oneOfType([number, string]),
};

export default TelegramIcon;
