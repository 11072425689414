import { useCallback } from 'react';
import useTransactionStore from 'stores/transaction-store';
import useUserQuery from 'hooks/queries/use-user-query';

function usePublicAddressAction(action) {
  const { user, isLoggedIn } = useUserQuery();

  return useCallback(
    ({ ...args }) => (
      isLoggedIn
        ? action({ publicAddress: user.publicAddress, ...args })
        : null
    ),
    [action, isLoggedIn, user?.publicAddress],
  );
}

export function useAddTransaction() {
  const add = useTransactionStore((state) => (state.add));
  return usePublicAddressAction(add);
}

export function useUpdateTransactionLastChecked() {
  const updateLastChecked = useTransactionStore((state) => (state.updateLastChecked));
  return usePublicAddressAction(updateLastChecked);
}

export function useUpdateTransactionStatus() {
  const updateStatus = useTransactionStore((state) => (state.updateStatus));
  return usePublicAddressAction(updateStatus);
}

export function useMarkTransactionAsSeen() {
  const markAsSeen = useTransactionStore((state) => (state.markAsSeen));
  return usePublicAddressAction(markAsSeen);
}

export function useClearFinishedTransaction() {
  const clearFinished = useTransactionStore((state) => (state.clearFinished));
  return usePublicAddressAction(clearFinished);
}

export function useDeleteTransaction() {
  const deleteTransaction = useTransactionStore((state) => (state.delete));
  return usePublicAddressAction(deleteTransaction);
}
