import { useEffect, useRef, useState } from 'react';

function useIntersectionObserver(options = {}) {
  const containerRef = useRef(null);
  const [isIntersect, setIsIntersect] = useState(false);

  useEffect(() => {
    const ref = containerRef;

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersect(entry.isIntersecting);
    }, options);
    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect(); // clean up observer
    };
  }, [options]);

  return {
    containerRef,
    isIntersect,
  };
}

export default useIntersectionObserver;
