import _ from 'lodash';
import create from 'zustand';
import produce from 'immer';

const useUserStore = create((set) => ({
  user: {},
  setUser: ({ user }) =>
    set(
      produce((state) => {
        const newState = _.cloneDeep(state);
        newState.user = { ...user };
        return newState;
      }),
    ),
}));

export default useUserStore;
