import { useRouter } from 'next/router';
import { useUnseenSubmissions } from 'stores/selectors/submissions-selectors';
import CollectionsDropdown from 'components/layout/collections-dropdown';
import Link from 'next/link';
import _ from 'lodash';
import mixpanel from 'libs/mixpanel';

export function getServerSideProps() {
  return {
    props: {},
  };
}

function HeaderNavigation() {
  const router = useRouter();
  const isQuests = router.pathname.startsWith('/app/quests');
  const isMyAnswers = router.pathname.startsWith('/app/my-submissions');
  const unseenSubmissions = useUnseenSubmissions();

  return (
    <div className="flex gap-6 items-center text-base text-[#D2D3DD]">
      <Link href="/app/quests" passHref>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a
          className={`
          ${isQuests
            ? 'text-[#FFFCFB] font-bold pb-[2px] border-b-2'
            : 'text-[#D2D3DD] pb-[4px] hover:text-[#E96036] font-bold hover:pb-[2px] hover:border-b-2'
            } whitespace-nowrap`}
          onClick={() => {
            mixpanel.track(
              '👆🏼Nav_Home',
              {},
              { send_immediately: true },
            );
          }}
        >
          Home
        </a>
      </Link>
      <Link href="/app/collections" passHref>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a onClick={() => {
          mixpanel.track(
            '👆🏼Nav_Collections',
            {},
            { send_immediately: true },
          );
        }}
        >
          <CollectionsDropdown />
        </a>
      </Link>
      <Link href="/app/my-submissions" passHref>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a
          className={`
          ${isMyAnswers
            ? 'text-[#FFFCFB] font-bold pb-[2px] border-b-2'
            : 'text-[#D2D3DD] pb-[4px] hover:text-[#E96036] font-bold hover:pb-[2px] hover:border-b-2'
            } whitespace-nowrap flex`}
          onClick={() => {
            sessionStorage.setItem('latestQuestTime', sessionStorage.getItem('latestClosesAt'));
            mixpanel.track(
              '👆🏼Nav_Submission',
              {},
              { send_immediately: true },
            );
          }}
        >
          My Submissions
          {_.size(unseenSubmissions) > 0 && (
            <div className="relative">
              <div className="w-2 h-2 bg-red-600 rounded-full" />
            </div>
          )}
        </a>
      </Link>
    </div>
  );
}

export default HeaderNavigation;
