import { MATIC_BALANCE } from 'libs/react-query/constants';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import BigNumber from 'bignumber.js';

import ContractsContext from 'contexts/contracts';
// hooks
import useUserQuery from 'hooks/queries/use-user-query';

export default function useMaticBalanceQuery(placeholderData) {
  const { user, isLoggedIn } = useUserQuery();

  const {
    provider,
  } = useContext(ContractsContext);

  const {
    data: balance,
    ...values
  } = useQuery(
    [MATIC_BALANCE, user?.publicAddress],
    () => provider.getBalance(user.publicAddress),
    {
      placeholderData,
      enabled: Boolean(isLoggedIn && typeof window !== 'undefined' && window.ethereum),
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    maticBalance: ethers.utils.formatEther(balance || 0),
    maticBalanceBn: BigNumber(ethers.utils.formatEther(balance || 0)),
    ...values,
  };
}
