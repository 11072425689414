// unauthenticated
export const POST_NONCE = '/auth/nonce';
export const POST_LOGIN = '/auth/login';

export const ENQUIRIES_SUBGRAPH = '/enquiries/subgraph';

export const QUEST_ALL = '/quests';

export const QUEST_SUBMISSION = '/quest-submissions';
export const ASSET_TOP = '/assets/top';

// authenticated
export const POST_LOGOUT = '/auth/logout';
export const GET_ME = '/api/users/current';
export const API_USERS = '/api/users';
export const API_CONFIRM_EMAIL = '/api/users/confirm-email';
export const API_SEND_EMAIL_VERIFICATION = '/api/users/send-email-verification';

export const API_SUBMISSION = '/api/submissions';
export const API_QUEST_SUBMISSION = '/api/quest-submissions';
export const API_QUEST_ITEM_SUBMISSION = '/api/quest-item-submissions';

export const API_TRANSACTION = '/api/transactions';

export const API_NOTIFICATION_SETTING = '/api/notification-settings';
