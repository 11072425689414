import axios from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};

function base({ method, url, params, data, jwt }) {
  return axios.request({
    method,
    url,
    baseURL: publicRuntimeConfig.serverUrl,
    params,
    data,
    ...(
      jwt && {
        headers: {
          Cookie: `user=${jwt}`,
        },
      }
    ),
  });
}

/**
 * Axios GET
 *
 * @function get
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.jwt - user jwt token
 */
export function get({ url, params, jwt }) {
  return base({
    method: 'get',
    url,
    params,
    jwt,
  });
}

/**
 * Axios POST
 *
 * @function post
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 * @param {object} params.jwt - user jwt token
 */
export function post({ url, params, data, jwt }) {
  return base({
    method: 'post',
    url,
    params,
    data,
    jwt,
  });
}

/**
 * Axios DELETE
 *
 * @function del
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 * @param {object} params.jwt - user jwt token
 */
export function del({ url, params, data, jwt }) {
  return base({
    method: 'delete',
    url,
    params,
    data,
    jwt,
  });
}

/**
 * Axios PUT
 * Updates a resource entirely
 *
 * @function put
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 * @param {object} params.jwt - user jwt token
 */
export function put({ url, params, data, jwt }) {
  return base({
    method: 'put',
    url,
    params,
    data,
    jwt,
  });
}

/**
 * Axios PATCH
 * Updates a resource partially
 *
 * @function patch
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 * @param {object} params.jwt - user jwt token
 */
export function patch({ url, params, data, jwt }) {
  return base({
    method: 'patch',
    url,
    params,
    data,
    jwt,
  });
}
