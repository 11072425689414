import {
  API_CONFIRM_EMAIL,
  API_SEND_EMAIL_VERIFICATION,
  API_USERS,
  GET_ME,
} from 'api/endpoints';
import { get, patch, post } from 'libs/axios';

async function getUserEnquirySubmission({ id, enquiryId }) {
  const res = await get({
    url: `${API_USERS}/${id}/enquiries/${enquiryId}/submission`,
  });

  return res;
}

async function getUserSubmissions({ id, offset, limit }) {
  const res = await get({
    url: `${API_USERS}/${id}/submissions`,
    params: {
      offset,
      limit,
    },
  });

  return res;
}

async function patchUser({ id, data }) {
  const res = await patch({
    url: `${API_USERS}/${id}`,
    data,
  });

  return res;
}

async function getCurrentUser() {
  const res = await get({
    url: GET_ME,
  });

  return res;
}

async function sendEmailVerification() {
  const res = await post({
    url: API_SEND_EMAIL_VERIFICATION,
  });

  return res;
}

async function verifyEmail({ token }) {
  const res = await post({
    url: API_CONFIRM_EMAIL,
    data: {
      token,
    },
  });

  return res;
}

const funcs = {
  getCurrentUser,
  patchUser,
  verifyEmail,
  sendEmailVerification,
  getUserSubmissions,
  getUserEnquirySubmission,
};

export default funcs;
