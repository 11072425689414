import getConfig from 'next/config';
import mixpanel from 'mixpanel-browser';

const { publicRuntimeConfig } = getConfig();

mixpanel.init(
  publicRuntimeConfig.mixpanelKey,
  { debug: publicRuntimeConfig?.appEnvironment === 'development' },
);

export default mixpanel;
