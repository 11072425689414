import { COLLECTION_LATEST_QUESTS } from 'libs/react-query/constants';
import { collectionApi } from 'api/fetchers';
import { getSubgraphEnquiries } from 'api/subgraphs/enquiry';
import { useQuery } from 'react-query';
import _ from 'lodash';

function useCollectionLatestQuestsQuery() {
  const {
    data,
    ...values
  } = useQuery(
    [COLLECTION_LATEST_QUESTS],
    async () => {
      const { data: quests } = await collectionApi.getCollectionLatestQuests();

      const enquiries = _.reduce(
        quests,
        (accu, quest) => {
          if (quest?.externalId && quest?.contractAddress) {
            return [
              ...accu,
              {
                externalId: quest.externalId,
                contractAddress: quest.contractAddress,
              },
            ];
          }
          return accu;
        },
        [],
      );

      if (!_.isEmpty(enquiries)) {
        const subgraphQuests = await getSubgraphEnquiries({ enquiries });

        const subgraphQuestsMapping = _.keyBy(subgraphQuests, 'id');
        return quests.map((quest) => ({
          ...quest,
          expertCount: subgraphQuestsMapping[`${quest?.contractAddress?.toLowerCase()}-${quest?.externalId}`]?.expertCount,
        }));
      }

      return quests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    ...values,
  };
}

export default useCollectionLatestQuestsQuery;
