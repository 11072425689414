/* eslint-disable camelcase */
import getConfig from 'next/config';

// lith token abi
import latestLithiumTokenAbi from 'smart-contracts/abis/testnet/lithium-token/2022-08-01.json';

// rp token abi
import latestReputationAbi from 'smart-contracts/abis/testnet/reputation/2022-08-31.json'; // develop branch

// enquiry pool abi
// https://github.com/Lithium-Finance/smart-contracts/commit/e612802d17bb15e89b87ef9afa60e0d08dca327c
import enquiryPoolAbiV1 from 'smart-contracts/abis/testnet/enquiry-pool/2022-12-11.json';

// https://github.com/Lithium-Finance/smart-contracts/blob/f7a138cfbba6cdbbb876dd44a2aca50dbe840dfe/data/abi/contracts/enquiry-pool/v2/EnquiryPoolV2.sol/EnquiryPoolV2.json
// update maxRPUSDMultiplier to maxRPTokenAMultiplier
import enquiryPoolAbiV2 from 'smart-contracts/abis/testnet/enquiry-pool/2023-01-12.json';

const { publicRuntimeConfig } = getConfig() || {};

const {
  blockchainNetwork,

  enquiryPoolAddressLookupV1,
  enquiryPoolAddressLookupV2,

  lithiumTokenAddressLookup,
  reputationTokenAddressLookup,
} = publicRuntimeConfig;

export const allAbiLookup = {
  // Enquiry Pool
  // staging
  [enquiryPoolAddressLookupV1.mumbai]: enquiryPoolAbiV1,
  [enquiryPoolAddressLookupV2.mumbai]: enquiryPoolAbiV2,

  // production
  [enquiryPoolAddressLookupV1.matic]: enquiryPoolAbiV1,
  [enquiryPoolAddressLookupV2.matic]: enquiryPoolAbiV2,

  [enquiryPoolAddressLookupV1.hardhat]: enquiryPoolAbiV1,
  [enquiryPoolAddressLookupV2.hardhat]: enquiryPoolAbiV2,

  // Lithium Token
  [lithiumTokenAddressLookup.mumbai]: latestLithiumTokenAbi, // staging
  [lithiumTokenAddressLookup.matic]: latestLithiumTokenAbi, // production
  [lithiumTokenAddressLookup.hardhat]: latestLithiumTokenAbi, // local

  // Reputation Token
  [reputationTokenAddressLookup.mumbai]: latestReputationAbi, // staging
  [reputationTokenAddressLookup.matic]: latestReputationAbi, // production
  [reputationTokenAddressLookup.hardhat]: latestReputationAbi, // local
};

export const abiLookup = {
  ENQUIRY_POOL_V1: allAbiLookup[enquiryPoolAddressLookupV1[blockchainNetwork]],
  ENQUIRY_POOL_V2: allAbiLookup[enquiryPoolAddressLookupV2[blockchainNetwork]],
  LITHIUM_TOKEN: allAbiLookup[lithiumTokenAddressLookup[blockchainNetwork]],
  REPUTATION_TOKEN: allAbiLookup[reputationTokenAddressLookup[blockchainNetwork]],
};
