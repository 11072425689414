import { chooseExplorer } from 'smart-contracts';
import { useMemo, useRef } from 'react';
import { useTransaction } from 'stores/selectors/transaction-selectors';
import ExternalLink from 'components/external-link';
import RecentTransactionsListItem from 'components/layout/recent-transactions-list-item';
import _ from 'lodash';
import useUserQuery from 'hooks/queries/use-user-query';

function RecentTransactionsList() {
  const scrollContainerRef = useRef(null);
  const { user } = useUserQuery();
  const transactions = useTransaction();

  const allTransactions = useMemo(
    () => [
      ...Object.values(transactions),
    ].sort(() => (false)),
    [transactions],
  );

  return (
    <div className="py-4 px-3 bg-neutral-900 rounded-2xl">
      {
        _.isEmpty(allTransactions) ? (
          <div className="text-center">
            <div className="font-bold mb-1">
              Recent Transactions
            </div>
            <ExternalLink
              type="text"
              href={`${chooseExplorer()}/address/${user?.publicAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center"
              size="small"
            >
              View on Polygonscan
            </ExternalLink>
          </div>
        ) : (
          <>
            <div className="font-bold mb-3">
              Recent Transactions
            </div>
            <div ref={scrollContainerRef} className="flex flex-col gap-3 max-h-[200px] overflow-y-auto">
              {
                allTransactions.map((transaction) => (
                  <RecentTransactionsListItem
                    transaction={transaction}
                    parentRef={scrollContainerRef.current}
                    key={`tnx-${transaction.transaction.hash}`}
                  />
                ))
              }
            </div>
          </>
        )
      }
    </div>
  );
}

RecentTransactionsList.propTypes = {

};

export default RecentTransactionsList;
