export const CURRENT_USER = 'CURRENT_USER';
export const ALL_USERS = 'ALL_USERS';
export const USER_ADMIN = 'USER_ADMIN';

export const POLYGON_BALANCE = 'POLYGON_BALANCE';
export const MATIC_BALANCE = 'MATIC_BALANCE';
export const LITH_BALANCE = 'LITH_BALANCE';
export const RP_BALANCE = 'RP_BALANCE';
export const RP_CLAIMED = 'RP_CLAIMED';
export const RP_AIRDROP_CLAIMED = 'RP_AIRDROP_CLAIMED';

// enquiries
export const ENQUIRY_PUBLIC = 'ENQUIRY_PUBLIC';
export const ENQUIRY = 'ENQUIRY';
export const ENQUIRY_DISTRIBUTION = 'ENQUIRY_DISTRIBUTION';
export const ENQUIRIES_PUBLIC = 'ENQUIRIES_PUBLIC';
export const ENQUIRIES = 'ENQUIRIES';

export const ENQUIRIES_TOP_PUBLIC = 'ENQUIRIES_TOP_PUBLIC';
export const ENQUIRIES_TOP = 'ENQUIRIES_TOP';
export const ENQUIRIES_LATEST_ANNOUNCED_PUBLIC = 'ENQUIRIES_LATEST_ANNOUNCED_PUBLIC';
export const ENQUIRIES_LATEST_ANNOUNCED = 'ENQUIRIES_LATEST_ANNOUNCED';
export const ENQUIRIES_PARTICIPATED = 'ENQUIRIES_PARTICIPATED';
export const ENQUIRIES_ANNOUNCED_PUBLIC = 'ENQUIRIES_ANNOUNCED_PUBLIC';
export const ENQUIRIES_ANNOUNCED = 'ENQUIRIES_ANNOUNCED';

export const NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS';
export const ENQUIRY_POOL_ENTITIES = 'ENQUIRY_POOL_ENTITIES';

// answers
export const ANSWER = 'ANSWER';

// quests
export const QUEST_ADMIN = 'QUEST_ADMIN';
export const QUESTS_ADMIN = 'QUESTS_ADMIN';

export const QUEST = 'QUEST';
export const QUESTS = 'QUESTS';
export const QUEST_ITEMS = 'QUEST_ITEMS';
export const QUESTS_PUBLIC = 'QUEST_PUBLIC';

export const QUESTS_PARTICIPATED = 'QUESTS_PARTICIPATED';
export const QUESTS_DISTRIBUTED = 'QUESTS_DISTRIBUTED';
export const QUESTS_DISTRIBUTED_PUBLIC = 'QUESTS_DISTRIBUTED_PUBLIC';

export const QUESTS_LIMIT = 5;

// quest items
export const QUEST_ITEMS_ADMIN = 'QUEST_ITEMS_ADMIN';
export const QUEST_ITEM_ADMIN = 'QUEST_ITEM_ADMIN';

// quest submissions
export const QUEST_SUBMISSION_ADMIN = 'QUEST_SUBMISSION_ADMIN';
export const QUEST_SUBMISSIONS_ADMIN = 'QUEST_SUBMISSIONS_ADMIN';
export const QUEST_SUBMISSIONS_USER = 'QUEST_SUBMISSION_USER';

export const SUBMISSIONS_BY_ENQUIRY = 'SUBMISSIONS_BY_ENQUIRY';
export const USER_SUBMISSIONS = 'USER_SUBMISSIONS';

// transactions
export const TRANSACTION_ADMIN = 'TRANSACTION_ADMIN';
export const TRANSACTIONS_ADMIN = 'TRANSACTIONS_ADMIN';

// external
export const NFT_FLOOR_PRICE_COLLECTION = 'NFT_FLOOR_PRICE_COLLECTION';
export const OPENSEA_COLLECTION = 'OPENSEA_COLLECTION';

// subgraph
export const USER_SUBMISSION = 'USER_SUBMISSION';

export const LEADERBOARD = 'LEADERBOARD';
export const LEADERBOARD_PUBLIC = 'LEADERBOARD_PUBLIC';

export const COLLECTION_FLOOR_PRICE = 'COLLECTION_FLOOR_PRICE';

// asset
export const ASSET_TOP = 'ASSET_TOP';

// collection
export const COLLECTION_MAPPING = 'COLLECTION_MAPPING';
export const COLLECTION_TOKEN_ANALYTICS = 'COLLECTION_TOKEN_ANALYTICS';
export const COLLECTION_PRICED_HISTORY = 'COLLECTION_PRICED_HISTORY';
export const COLLECTION_LATEST_QUESTS = 'COLLECTION_LATEST_QUESTS';
export const COLLECTION_PRICING_RESULTS = 'COLLECTION_PRICING_RESULTS';
