import { bool, number } from 'prop-types';
import Image from 'next/image';

function LithIcon({
  // align with Charlie for darkMode and circle
  darkMode = false,
  withCircle = false,
  size = 24,
  ...otherProps
}) {
  return (
    <Image
      width={size}
      height={size}
      layout="fixed"
      alt={`LITH Icon  ${darkMode ? 'Light' : 'Dark'}${withCircle ? ' With Circle' : ''}`}
      src={`/static/images/icons/lith-icons/lith-${darkMode ? 'light' : 'dark'}${withCircle ? '-circle' : ''}.svg`}
      {...otherProps}
    />
  );
}

LithIcon.propTypes = {
  darkMode: bool,
  withCircle: bool,
  size: number,
};

export default LithIcon;
