import {
  API_QUEST_ITEM_SUBMISSION,
  API_QUEST_SUBMISSION,
  QUEST_SUBMISSION,
} from 'api/endpoints';
import { get, patch, post } from 'libs/axios';

/**
 * POST get quest submissions with userId, questIds
 *
 * @param {object} param
 * @param {string} param.status
 * @param {number} param.offset
 * @param {number} param.limit
 * @returns {object}
 */
async function getQuestSubmissionsByQuestIds({ questIds }) {
  const res = await post({
    url: `${API_QUEST_SUBMISSION}`,
    data: {
      questIds,
    },
  });

  return res;
}

/**
 * POST get quest item submissions with userId, questIds
 *
 * @param {object} param
 * @param {string} param.status
 * @param {number} param.offset
 * @param {number} param.limit
 * @returns {object}
 */
async function getQuestItemSubmissionsByQuestIds({ questIds }) {
  const res = await post({
    url: `${API_QUEST_ITEM_SUBMISSION}`,
    data: {
      questIds,
    },
  });

  return res;
}

async function patchQuestSubmission({ id, data }) {
  const res = await patch({
    url: `${API_QUEST_SUBMISSION}/${id}`,
    data,
  });

  return res;
}

async function getLeaderboard({ mode }) {
  const res = await get({
    url: `${QUEST_SUBMISSION}/leaderboard`,
    params: { mode },
  });

  return res;
}

async function getLeaderboardAndOwnRank({ mode, userId }) {
  const res = await get({
    url: `${API_QUEST_SUBMISSION}/leaderboard`,
    params: { mode, userId },
  });

  return res;
}

const funcs = {
  getQuestSubmissionsByQuestIds,
  getQuestItemSubmissionsByQuestIds,
  patchQuestSubmission,
  getLeaderboard,
  getLeaderboardAndOwnRank,
};

export default funcs;
