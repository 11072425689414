import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  message,
} from 'antd';
import DiscordIcon from 'components/icons/discord-icon';
import Image from 'next/image';
import MediumIcon from 'components/icons/medium-icon';
import TelegramIcon from 'components/icons/telegram-icon';
import TwitterIcon from 'components/icons/twitter-icon';
import mixpanel from 'libs/mixpanel';

const { Footer } = Layout;

function UserFooter() {
  function onFormFinished({ email }) {
    mixpanel.track('👆🏼Stay in touch', { email });
    message.success("You're now subscribed to Lithium Finance for the latest updates!");
  }

  return (
    <Footer className="px-[4%] pt-8 pb-10 flex flex-col items-center bg-[#1E2024] text-white flex-shrink-0">
      <div className="max-w-[1200px] w-full">
        <Image
          alt="logo"
          width={174}
          height={22}
          src="/static/images/lithium/lithium-banner-light.png"
          loading="eager"
          quality={50}
        />

        <div className="md:flex gap-12 justify-between">
          <div className="mt-6">
            <div className="text-lg font-bold mb-4">Stay in Touch</div>
            <Form onFinish={onFormFinished}>
              <div className="flex gap-4">
                <Form.Item className="grow mb-4" name="email" rules={[{ type: 'email' }]}>
                  <Input
                    className="h-12"
                    placeholder="Enter your email address"
                  />
                </Form.Item>
                <Button
                  type="secondary"
                  htmlType="submit"
                  onClick={() => mixpanel.track('👆🏼Subscribe now')}
                >
                  Sign up
                </Button>
              </div>
            </Form>
            <div className="text-sm">
              By entering your email address,
              you agree to us storing your information and receiving emails from us.
            </div>
          </div>

          <div className="mt-6 shrink-0">
            <div className="text-lg font-bold mb-4">Join Our Community</div>

            <div className="flex gap-2">
              <a
                href="https://twitter.com/Lithiumfinance"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => mixpanel.track('👆🏼Socials', { platform: 'twitter', location: 'footer' })}
              >
                <TwitterIcon size={36} />
              </a>
              <a
                href="https://t.me/lithiumfinanceofficial/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => mixpanel.track('👆🏼Socials', { platform: 'telegram', location: 'footer' })}
              >
                <TelegramIcon size={36} />
              </a>
              <a
                href="https://medium.com/lithiumfinance"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => mixpanel.track('👆🏼Socials', { platform: 'medium', location: 'footer' })}
              >
                <MediumIcon size={36} />
              </a>
              <a
                href="https://discord.gg/lithiumfinanceofficial"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => mixpanel.track('👆🏼Socials', { platform: 'discord', location: 'footer' })}
              >
                <DiscordIcon size={36} />
              </a>
            </div>
          </div>
        </div>

        <Divider className="border-gray-300" />

        <div className="md:flex justify-between text-sm">
          <div className="flex justify-between mb-4 md:mb-0">
            <div className="text-white">
              © 2023 Lithium Finance. All Rights Reserved. -
              &nbsp;
              <a className="text-[#FF7826] hover:text-[#E96036]" href="mailto:hello@lith.finance">Inquiries</a>
            </div>
          </div>
          <div className="flex">
            <div className="mr-8">
              <a
                className="text-[#8687A0] hover:text-[#E96036]"
                href="https://www.certik.com/projects/lithium"
                rel="noopener noreferrer"
                target="_blank"
              >
                Audited by Certik
              </a>
            </div>
            <div className="mr-8">
              <a
                className="text-white hover:text-[#E96036]"
                href="https://lith-finance.notion.site/Lithium-Finance-Privacy-Policy-57cc4ab92c4c4eca83fc794c3f66e491"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
            <div>
              <a
                className="text-white hover:text-[#E96036]"
                href="https://lith-finance.notion.site/Lithium-Finance-Terms-of-Service-c77aa2f729f74045b7871c4326c63e68"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </Footer>
  );
}

UserFooter.propTypes = {

};

export default UserFooter;
