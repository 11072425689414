import { useCallback } from 'react';
import useSubmissionsStore from 'stores/submissions-store';
import useUserQuery from 'hooks/queries/use-user-query';

function usePublicAddressAction(action) {
  const { user, isLoggedIn } = useUserQuery();

  return useCallback(
    ({ ...args }) =>
      (isLoggedIn ? action({ publicAddress: user.publicAddress, ...args }) : null),
    [action, isLoggedIn, user?.publicAddress],
  );
}

export function useAddSubmissions() {
  const add = useSubmissionsStore((state) => state.add);
  return usePublicAddressAction(add);
}

export function useMarkSubmissionsAsSeen() {
  const markAsSeen = useSubmissionsStore((state) => state.markAsSeen);
  return usePublicAddressAction(markAsSeen);
}
