import { Button, message } from 'antd';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import { func } from 'prop-types';
import { identifyNetwork } from 'smart-contracts';
import { shortenString } from 'utils';
import Image from 'next/image';
import Link from 'next/link';
import _ from 'lodash';
import mixpanel from 'libs/mixpanel';

// components
import DiscordIcon from 'components/icons/discord-icon';
import MediumIcon from 'components/icons/medium-icon';
import MetamaskIcon from 'components/icons/metamask-icon';
import TelegramIcon from 'components/icons/telegram-icon';
import TwitterIcon from 'components/icons/twitter-icon';

// hooks
import ExternalLink from 'components/external-link';
import LoadingCircleStatus from 'components/loading-circle-status';
import RecentTransactionsList from 'components/layout/recent-transactions-list';
import useDisconnect from 'hooks/use-disconnect';
import useUserQuery from 'hooks/queries/use-user-query';

function HeaderProfileMenu({
  onClose,
}) {
  const { user } = useUserQuery();

  const disconnect = useDisconnect();

  const shortenedAddress = shortenString(user.publicAddress, 5, 7);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(user.publicAddress);
    message.success('Successfully copied to clipboard!');
  };

  return (
    <div className="pt-5 pb-3 text-white bg-neutral-800 shadow-md rounded-2xl z-[100]">
      <div className="px-6 flex items-center">
        <div className="w-[60px] h-[60px] mr-3 box-content rounded-full border border-neutral-600 z-10">
          <LoadingCircleStatus>
            <Image
              alt="Profile avatar"
              width={60}
              height={60}
              src={'/static/images/profilepage-avatar.svg'}
            />
          </LoadingCircleStatus>
        </div>

        <div>
          <div className="text-lg font-bold">{user.name ?? 'Unnamed'}</div>
          <Button
            type="text"
            className="flex items-center justify-start -ml-2 text-white"
            onClick={handleCopyAddress}
            size="small"
          >
            {shortenedAddress}
            <DocumentDuplicateIcon className="ml-2" width={12} height={12} />
          </Button>
        </div>
      </div>

      <div className="px-6 py-3 flex justify-between gap-6">
        <div className="flex gap-2">
          <div className="px-3 pt-2 pb-[10px] flex items-center rounded-full bg-neutral-700">
            <MetamaskIcon width={16} height={16} />
          </div>

          <div className="px-3 py-2 text-xs flex items-center rounded-full bg-neutral-700">
            {_.includes(['Mumbai', 'Matic'], identifyNetwork(window.ethereum?.networkVersion))
              ? 'Polygon'
              : identifyNetwork(window.ethereum?.networkVersion)}
          </div>
        </div>

        <Button type="secondary" className="px-9" size="small" onClick={disconnect}>Disconnect</Button>
      </div>

      <div className="flex flex-col">
        <Link href="/app/my-profile" passHref>
          <Button
            type="text"
            size="small"
            className="px-6 py-0 text-white hover:bg-current text-left rounded-none"
            onClick={() => {
              onClose();
              mixpanel.track('👆🏼Edit Profile');
            }}
          >
            My Profile
          </Button>
        </Link>
        <Link href="/app/how-it-works" passHref>
          <Button
            type="text"
            size="small"
            className="px-6 py-0 text-white hover:bg-current text-left rounded-none"
            onClick={() => {
              onClose();
              mixpanel.track('👆🏼HIW');
            }}
          >
            How It Works
          </Button>
        </Link>
        <ExternalLink
          className="px-6 py-0 text-white hover:bg-current text-left rounded-none flex items-center"
          type="text"
          size="small"
          iconSize={16}
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.lith.finance/guides/where-to-get-lith"
          onClick={() => {
            onClose();
            mixpanel.track('👆🏼Buy LITH');
          }}
        >
          Buy LITH
        </ExternalLink>
        <ExternalLink
          className="px-6 py-0 text-white hover:bg-current text-left rounded-none flex items-center"
          type="text"
          size="small"
          iconSize={16}
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.lith.finance/"
          onClick={() => {
            onClose();
            mixpanel.track('Docs');
          }}
        >
          Docs
        </ExternalLink>
      </div>
      <div className="mx-6 mt-2">
        <RecentTransactionsList />
      </div>

      <div className="px-8 py-8 flex justify-between">
        <a
          href="https://twitter.com/Lithiumfinance"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            onClose();
            mixpanel.track('👆🏼Socials', { platform: 'twitter', location: 'profile menu' });
          }}
        >
          <TwitterIcon size={36} />
        </a>
        <a
          href="https://t.me/lithiumfinanceofficial/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            onClose();
            mixpanel.track('👆🏼Socials', { platform: 'telegram', location: 'profile menu' });
          }}
        >
          <TelegramIcon size={36} />
        </a>
        <a
          href="https://medium.com/lithiumfinance"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            onClose();
            mixpanel.track('👆🏼Socials', { platform: 'medium', location: 'profile menu' });
          }}
        >
          <MediumIcon size={36} />
        </a>
        <a
          href="https://discord.gg/lithiumfinanceofficial"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            onClose();
            mixpanel.track('👆🏼Socials', { platform: 'discord', location: 'profile menu' });
          }}
        >
          <DiscordIcon size={36} />
        </a>
      </div>
    </div>
  );
}

HeaderProfileMenu.propTypes = {
  onClose: func,
};

export default HeaderProfileMenu;
