import { LITH_BALANCE } from 'libs/react-query/constants';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import BigNumber from 'bignumber.js';
import ContractsContext from 'contexts/contracts';
import useUserQuery from 'hooks/queries/use-user-query';

export default function useLithBalanceQuery(placeholderData) {
  const { user, isLoggedIn } = useUserQuery();

  const {
    lithTokenReadContract,
  } = useContext(ContractsContext);

  const {
    data: balance,
    ...values
  } = useQuery(
    [LITH_BALANCE, user?.publicAddress],
    () => lithTokenReadContract.balanceOf(user.publicAddress),
    {
      placeholderData,
      enabled: Boolean(isLoggedIn && typeof window !== 'undefined' && window.ethereum),
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    lithBalance: ethers.utils.formatEther(balance || 0),
    lithBalanceBn: BigNumber(ethers.utils.formatEther(balance || 0)),
    ...values,
  };
}
