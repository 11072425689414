import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { node } from 'prop-types';
import ContractsContext from 'contexts/contracts';
import useIsWindowVisible from 'hooks/use-is-window-visible';

const MISSING_PROVIDER = Symbol('MISSING_PROVIDER');
const BlockNumberContext = createContext(MISSING_PROVIDER);

function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext);
  if (blockNumber === MISSING_PROVIDER) {
    throw new Error(
      'BlockNumber hooks must be wrapped in a <BlockNumberProvider>',
    );
  }
  return blockNumber;
}

/** Requires that BlockUpdater be installed in the DOM tree. */
export default function useBlockNumber() {
  return useBlockNumberContext().value;
}

export function useFastForwardBlockNumber() {
  return useBlockNumberContext().fastForward;
}

export function BlockNumberProvider({ children }) {
  const { provider } = useContext(ContractsContext);

  const [block, setBlock] = useState();

  const onBlock = useCallback(
    (newBlock) => {
      setBlock((currBlock) => {
        if (!currBlock || currBlock < newBlock) {
          return newBlock;
        }

        return currBlock;
      });
    },
    [],
  );

  const windowVisible = useIsWindowVisible();
  useEffect(() => {
    if (provider && windowVisible) {
      provider
        .getBlockNumber()
        .then(onBlock)
        .catch((error) => {
          console.error('Failed to get block number for chainId', error);
        });

      provider.on('block', onBlock);
      return () => {
        provider.removeListener('block', onBlock);
      };
    }
    return undefined;
  }, [onBlock, provider, windowVisible]);

  const value = useMemo(
    () => ({
      value: block,
      fastForward: (_block) => setBlock(_block),
    }),
    [block],
  );
  return (
    <BlockNumberContext.Provider value={value}>
      {children}
    </BlockNumberContext.Provider>
  );
}

BlockNumberProvider.propTypes = {
  children: node,
};
