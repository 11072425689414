import { logout } from 'services/auth';
import mixpanel from 'libs/mixpanel';

/**
 * Get disconnect function
 * @returns {function(): void} disconnect function
 */
function useDisconnect() {
  async function disconnect() {
    await logout();

    mixpanel.track('✅ Disconnect Wallet');

    window.location.replace('/app/quests');
  }

  return disconnect;
}

export default useDisconnect;
