import { Button } from 'antd';
import { bool } from 'prop-types';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import mixpanel from 'libs/mixpanel';

// components
import DiscordIcon from 'components/icons/discord-icon';
import MediumIcon from 'components/icons/medium-icon';
import TelegramIcon from 'components/icons/telegram-icon';
import TwitterIcon from 'components/icons/twitter-icon';

function HeaderSocialConnectControls({ showConnect }) {
  const [connectUrl, setConnectUrl] = useState('/app/connect');

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.append('redirection-path', window.location.pathname);
    setConnectUrl(`/app/connect?${searchParams.toString()}`);
  }, []);

  return (
    <div className="ml-auto flex items-center gap-9">
      <div className="flex text-white justify-between items-center gap-2">
        <a
          className="w-9 h-9 flex justify-center items-center text-white"
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/lithiumfinance"
          onClick={() => mixpanel.track(
            '👆🏼Socials',
            { platform: 'twitter', location: 'navigation bar' },
            { send_immediately: true },
          )}
        >
          <TwitterIcon size={36} />
        </a>
        <a
          className="w-9 h-9 flex justify-center items-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://t.me/lithiumfinanceofficial"
          onClick={() => mixpanel.track(
            '👆🏼Socials',
            { platform: 'telegram', location: 'navigation bar' },
            { send_immediately: true },
          )}
        >
          <TelegramIcon size={36} />
        </a>
        <a
          className="w-9 h-9 flex justify-center items-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://medium.com/lithiumfinance"
          onClick={() => mixpanel.track(
            '👆🏼Socials',
            { platform: 'medium', location: 'navigation bar' },
            { send_immediately: true },
          )}
        >
          <MediumIcon size={36} />
        </a>
        <a
          className="w-9 h-9 flex justify-center items-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.gg/lithiumfinanceofficial"
          onClick={() => mixpanel.track(
            '👆🏼Socials',
            { platform: 'discord', location: 'navigation bar' },
            { send_immediately: true },
          )}
        >
          <DiscordIcon size={36} />
        </a>
        {showConnect && (
          <Link href={connectUrl} passHref>
            <Button
              type="secondary"
              onClick={() => mixpanel.track('👆🏼ConnectWallet', { location: 'navigation bar' })}
            >
              Connect Wallet
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}

HeaderSocialConnectControls.propTypes = {
  showConnect: bool,
};

export default HeaderSocialConnectControls;
