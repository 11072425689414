import { gql } from 'graphql-request';
import { subgraphClient } from 'libs/graphql-client';

/**
 * Get user submission record, return quest status and signatures (same as transactions)
 *
 * @param {object} param
 * @param {string} param.publicAddress
 * @returns {Promise<object[]>}
 */
export async function getGraphParticipatedQuestData({
  publicAddress,
}) {
  const query = gql`
    query($expert: String!) {
      enquiryUserAnswers(
        where: {
          expert: $expert
        }
      ) {
          id
          expert
          enquiry {
            endTime
            enquiryId
            status
            userLimit
          }
          latestAnswer {
            signature
            status
            actionLogs(orderBy: timestamp, orderDirection: desc) {
              hash
              status
              timestamp
            }
            stakeAmount
            stakeToken
            rp
          }
        }
    }
  `;

  const res = await subgraphClient.request(
    query,
    {
      expert: publicAddress.toLowerCase(),
    },
  );

  return res?.enquiryUserAnswers.map((answer) => ({
    graphContractAddress: answer.id.split('-')[0],
    graphEndTime: answer.enquiry.endTime,
    externalId: answer.enquiry.enquiryId,
    graphAnswerStatus: answer.latestAnswer.status,
    graphQuestStatus: answer.enquiry.status,
    graphSignature: answer.latestAnswer.signature,
    graphHash: answer.latestAnswer.actionLogs[0].hash,
    graphActionTimestamp: answer.latestAnswer.actionLogs[0].timestamp,
    graphUserLimit: answer.enquiry.userLimit,
    graphStakeAmount: answer.latestAnswer.stakeAmount,
    graphStakeToken: answer.latestAnswer.stakeToken,
    graphRp: answer.latestAnswer.rp,
  })) ?? [];
}
