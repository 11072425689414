import { dayjs } from 'libs/dayjs';
import { func, node, object, oneOfType } from 'prop-types';
import { message } from 'antd';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Bugsnag from 'libs/bugsnag';
import _, { isEmpty } from 'lodash';
import mixpanel from 'libs/mixpanel';

// hooks
import { useAddSubmissions } from 'stores/actions/submissions-actions';
import useQuestSubmissionQuery from 'hooks/queries/use-quest-submission-query';
import useUserQuery from 'hooks/queries/use-user-query';
import useUserStore from 'stores/user-store';

// layouts
import AdminLayout from 'layouts/admin';
import LandingPageLayout from 'layouts/landing-page';
import UserLayout from 'layouts/user';

// components
import IsLoading from 'components/is-loading';

import {
  DB_QUEST_STATUS_MAPPING,
  DB_QUEST_SUBMISSION_STATUS_MAPPING,
  GRAPH_ANSWER_STATUS_MAPPING,
  GRAPH_QUEST_STATUS_MAPPING,
} from 'constants/status-mapping';

const UNAUTHENTICATED_REDIRECT_PATHS = [
  '/app/my-profile',
];

function Base({ children, pageProps }) {
  const router = useRouter();
  const { pathname } = router;

  const {
    isLoggedIn,
    isLoading,
    user,
  } = useUserQuery();
  const setUser = useUserStore((state) => (state.setUser));

  // initialize user submissions
  const {
    isLoading: isQuestSubmissionsLoading,
    questSubmissionsPages,
  } = useQuestSubmissionQuery();

  const addSubmissions = useAddSubmissions();

  useEffect(() => {
    if (!isEmpty(user)) {
      setUser(user);
      Bugsnag.setUser(user.id, user.email, user.publicAddress);

      mixpanel.identify(user.id);
      mixpanel.people.set({
        $userId: user.id,
        $publicAddress: user.publicAddress,
        $role: user.role,
      });
    }
  }, [setUser, user]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const toasterMessage = searchParams.get('toaster-message');
    const toasterType = searchParams.get('toaster-type');

    if (toasterMessage && toasterType) {
      searchParams.delete('toaster-message');
      searchParams.delete('toaster-type');
      message[toasterType](toasterMessage);

      router.replace(
        { pathname, query: searchParams.toString() },
        undefined,
        { shallow: true },
      );
    }
  }, [pathname, router]);

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(questSubmissionsPages)) {
      const claimableQuests = _.map(
        _.filter(
          questSubmissionsPages,
          (a) => (
            _.includes(GRAPH_QUEST_STATUS_MAPPING.claimable, a.graphQuestStatus)
            && _.includes(GRAPH_ANSWER_STATUS_MAPPING.claimable, a.graphAnswerStatus)
            && _.includes(DB_QUEST_STATUS_MAPPING.claimable, a.dbQuestStatus)
            && _.includes(
              DB_QUEST_SUBMISSION_STATUS_MAPPING.claimable,
              a.questSubmission?.status,
            )
          ),
        ),
        (q) => (q.data.questId),
      );

      const refundableQuests = _.map(
        _.filter(
          questSubmissionsPages,
          (q) => (
            // no 'CANCEL' status in graph, user limit = 0 or exceed max buffer time
            (q.graphQuestStatus === 'REFUNDED'
              && q.graphAnswerStatus !== 'REFUNDED')
            || (
              q.dbQuestStatus === 'ACTIVE'
              && dayjs.unix().isAfter(dayjs.unix(q.graphEndTime).add(14, 'days'))
            )
          ),
        ),
        (q) => (q.data.questId),
      );

      addSubmissions({ questIds: claimableQuests, submissionGroup: 'CLAIMABLE' });
      addSubmissions({ questIds: refundableQuests, submissionGroup: 'REFUNDABLE' });
    }
  }, [user, questSubmissionsPages, addSubmissions]);

  if (isLoading || isQuestSubmissionsLoading) {
    return <IsLoading />;
  }

  if (
    typeof window !== 'undefined'
    && pathname.includes('/submission-success')
    && _.isEmpty(user)
  ) {
    router.push('/app/connect');
    return null;
  }

  if (pathname.startsWith('/app/admin') && user?.role !== 'ADMIN') {
    const searchParams = new URLSearchParams();

    searchParams.append('redirection-path', pathname);

    if (typeof window === 'undefined') return null;

    router.push(`/app/connect?${searchParams.toString()}`);
    return null;
  }

  // add all unauthenticated redirect here
  if (!isLoggedIn && UNAUTHENTICATED_REDIRECT_PATHS.some((p) => (!!pathname.startsWith(p)))) {
    const searchParams = new URLSearchParams();

    searchParams.append('redirection-path', pathname);
    searchParams.append('toaster-message', 'Please login');
    searchParams.append('toaster-type', 'error');

    router.push(`/app/connect?${searchParams.toString()}`);
    return null;
  }

  if (!isEmpty(user) && router.pathname.startsWith('/app/connect')) {
    router.push('/app/quests');
    return null;
  }

  if (router.pathname === '/') {
    return (
      <LandingPageLayout pageProps={pageProps}>
        {children}
      </LandingPageLayout>
    );
  }

  if (router.pathname.startsWith('/app/connect')) {
    return (
      <UserLayout pageProps={pageProps}>
        {children}
      </UserLayout>
    );
  }

  const Layout = user?.role === 'ADMIN' ? (
    <AdminLayout pageProps={pageProps}>
      {children}
    </AdminLayout>
  ) : (
    <UserLayout pageProps={pageProps}>
      {children}
    </UserLayout>
  );

  return Layout;
}

Base.propTypes = {
  children: oneOfType([node, func]).isRequired,
  pageProps: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Base;
