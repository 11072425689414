import _ from 'lodash';

export async function mergeQuestsWithData({
  graphQuestData,
  dbQuests,
  dbQuestSubmissions: dbQuestSubmissionsLookup,
  dbQuestItemSubmissions: dbQuestItemSubmissionsLookup,
  dbTransactions,
  imgs,
}) {
  // rename status to dbQuestStatus, merge quest with questSubmission, questItemSubmission
  const processedDbQuests = dbQuests.map((item) => {
    const newItem = item;
    newItem.dbQuestStatus = newItem.status;
    newItem.dbQuestId = newItem.id;
    delete newItem.status;
    delete newItem.id;

    // always only one quest submission per quest
    const questSubmission = !_.isEmpty(dbQuestSubmissionsLookup[item.dbQuestId])
      && dbQuestSubmissionsLookup[item.dbQuestId][0];
    return {
      ...newItem,
      questSubmission,
      questItemSubmissions: dbQuestItemSubmissionsLookup[item.dbQuestId],
      ...{
        imgItems: imgs[item.dbQuestId],
      },
    };
  });

  // lookup: externalId
  const dbQuestsLookup = _.keyBy(processedDbQuests, (q) => (`${_.toLower(q.contractAddress)}-${q.externalId}`));

  // rename ids to transactionIds
  const processedTransactions = dbTransactions.map((item) => {
    const newItem = item;
    newItem.transactionId = newItem.id;
    delete newItem.id;
    return newItem;
  });

  // lookup: hash, cancel transaction may not have signature
  const dbTransactionsLookupByHash = _.keyBy(processedTransactions, (a) => (a.hash));
  const dbTransactionsLookupBySignature = _.keyBy(processedTransactions, (a) => (a.signature));

  // merge all data
  const mergedQuests = graphQuestData.map((quest) => ({
    ...quest,
    ...dbQuestsLookup[`${_.toLower(quest.graphContractAddress)}-${quest.externalId}`],
    ...(dbTransactionsLookupByHash[quest.graphHash]
    || dbTransactionsLookupBySignature[quest.graphSignature]),
  }));

  return mergedQuests;
}
