import { Button, Popover } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import IsLoading from 'components/is-loading';
import Link from 'next/link';
import _ from 'lodash';
import useCollectionLatestQuestsQuery from 'hooks/queries/use-collection-latest-quests-query';
import useCollectionMappingQuery from 'hooks/queries/use-collection-mapping-query';

export default function CollectionsDropdown() {
  const router = useRouter();
  const isCollections = router.pathname.startsWith('/app/collections');
  const [expanded, setExpanded] = useState(false);
  const collectionsContainerRef = useRef(null);

  const {
    isLoading: isCollectionMappingLoading,
    data: collectionMapping,
  } = useCollectionMappingQuery();

  const {
    isLoading: isCollectionLatestQuestsLoading,
    data: collectionLatestQuests,
  } = useCollectionLatestQuestsQuery();

  const latestQuestMapping = _.keyBy(collectionLatestQuests, 'slug');
  const mergedQuests = collectionMapping?.COLLECTIONS.map((item) => ({
    ...item,
    ...latestQuestMapping[item.slug],
  }));

  const existingQuests = _.filter(
    mergedQuests,
    (item) => (item?.questType !== 'NEW' || item?.questType === null),
  );
  const campaignQuests = _.filter(
    mergedQuests,
    (item) => (item?.questType === 'NEW'),
  );

  if (isCollectionMappingLoading || isCollectionLatestQuestsLoading) {
    return (
      <Popover
        placement="bottom"
        content={(<IsLoading />)}
      >
        <button
          type="button"
          className={`
        flex items-center
          ${expanded || isCollections
            ? 'text-[#E96036] font-bold pb-[2px] border-b-2 transition-all duration-75'
            : 'text-[#D2D3DD] pb-[4px] hover:text-[#E96036] font-bold hover:pb-[2px] hover:border-b-2 transition-all duration-75'}
        `}
        >
          <span className="mr-1">Collections</span>
          {expanded ? <UpOutlined /> : <DownOutlined />}
        </button>
        <div ref={collectionsContainerRef} className="ant-popover-no-style" />
      </Popover>
    );
  }

  return (
    <Popover
      placement="bottom"
      getPopupContainer={() => collectionsContainerRef.current}
      content={(
        <div className="relative z-100 text-white rounded-xl border border-[#5B5C6C]">
          <div className="bg-[#1E2024] flex grow p-4 rounded-xl top-5">
            <div className="flex flex-col px-4 py-2">
              <span>Collections</span>
              <div className="grid grid-flow-col grid-rows-4 pt-6">
                {existingQuests.map((item) => (
                  <div className="flex items-center pr-6" key={`${item.slug}-${collectionMapping?.SLUG_COLLECTION_MAPPING[item.slug]}`}>
                    <Link href={`/app/collections/${item.slug}`}>
                      <Button type="secondary" ghost className="p-0 m-0 border-none flex items-center gap-2">
                        <div className="h-8 w-8 relative rounded-full overflow-hidden border border-[#8687A0]">
                          <Image src={`/static/images/quests/${item.slug}-logo.svg`} layout="fill" priority />
                        </div>
                        {collectionMapping?.SLUG_COLLECTION_MAPPING[item.slug]}
                      </Button>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {campaignQuests.length > 0 && (
              <>
                <div className="border-r border-[#5B5C6C]" />
                <div className="flex flex-col px-4 py-2">
                  <span>Campaigns</span>
                  <div className="grid grid-flow-col grid-rows-4 pt-6">
                    {campaignQuests.map((item) => (
                      <div className="flex items-center pr-6" key={`${item.slug}-${collectionMapping?.SLUG_COLLECTION_MAPPING[item.slug]}`}>
                        <Link href={`/app/collections/${item.slug}`}>
                          <Button type="secondary" ghost className="p-0 m-0 border-none flex items-center gap-2">
                            <div className="h-8 w-8 relative rounded-full overflow-hidden border border-[#8687A0]">
                              <Image src={`/static/images/quests/${item.slug}-logo.svg`} layout="fill" priority />
                            </div>
                            {collectionMapping?.SLUG_COLLECTION_MAPPING[item.slug]}
                          </Button>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      open={expanded}
      onOpenChange={setExpanded}
      destroyTooltipOnHide
      className="z-[101]"
    >
      <button
        type="button"
        className={`
        flex items-center
          ${expanded || isCollections
          ? 'text-[#E96036] font-bold pb-[2px] border-b-2 transition-all duration-75'
          : 'text-[#D2D3DD] pb-[4px] hover:text-[#E96036] font-bold hover:pb-[2px] hover:border-b-2 transition-all duration-75'}
        `}
      >
        <span className="mr-1">Collections</span>
        {expanded ? <UpOutlined /> : <DownOutlined />}
      </button>
      <div ref={collectionsContainerRef} className="ant-popover-no-style" />
    </Popover>
  );
}

CollectionsDropdown.propTypes = {};
