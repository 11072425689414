import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { Button } from 'antd';
import { bool, node, number, objectOf, string } from 'prop-types';

function ExternalLink({
  href,
  ignoreBaseStyle,
  className,
  style,
  children,
  iconSize = 20,
  showIcon = true,
  ...otherProps
}) {
  return (
    <Button
      type="text"
      className={`${className}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={style}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {children}
      {showIcon && <ArrowTopRightOnSquareIcon width={iconSize} height={iconSize} className="ml-0.5 inline align-text-bottom text-inherit" />}
    </Button>
  );
}

ExternalLink.propTypes = {
  href: string,
  ignoreBaseStyle: bool,
  className: string,
  style: objectOf(string),
  children: node,
  iconSize: number,
  showIcon: bool,
};

export default ExternalLink;
