export const GRAPH_QUEST_STATUS_MAPPING = {
  claimable: ['POSTED_MERKLE_ROOT', 'POSTED_CALCULATION_RESULT'],
  claimed: ['POSTED_MERKLE_ROOT', 'POSTED_CALCULATION_RESULT'],
  refundable: ['REFUNDED'],
  refunded: ['REFUNDED'],
};

export const GRAPH_ANSWER_STATUS_MAPPING = {
  claimable: ['CREATED', 'UPDATED'],
  claimed: ['CLAIMED'],
  refundable: ['CREATED', 'UPDATED'],
  refunded: ['REFUNDED'],
};

export const DB_QUEST_STATUS_MAPPING = {
  live: ['ACTIVE', 'CLOSED'],
  claimable: ['DISTRIBUTED'],
  claimed: ['DISTRIBUTED'],
  refundable: ['ACTIVE', 'FAILED'],
  refunded: ['CLOSED', 'FAILED'],
};

export const DB_QUEST_SUBMISSION_STATUS_MAPPING = {
  claimable: ['CLAIM_PENDING'],
  claimed: ['CLAIMED'],
};
