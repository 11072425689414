import { oneOf } from 'prop-types';
import Image from 'next/image';

function MetamaskIcon({
  type,
  ...otherProps
}) {
  switch (type) {
    default:
      return (
        <Image
          width={24}
          height={24}
          layout="fixed"
          alt="metamask"
          src="/static/images/icons/metamask-40x40.png"
          {...otherProps}
        />
      );
  }
}

MetamaskIcon.propTypes = {
  type: oneOf([]),
};

export default MetamaskIcon;
