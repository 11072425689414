import { Button, Layout } from 'antd';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { element } from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import UserFooter from 'layouts/footer';
import mixpanel from 'libs/mixpanel';

// components
import HeaderMobileMenuTrigger from 'layouts/header-mobile-menu-trigger';

// context
import WindowSizeContext from 'contexts/window-size';

// hooks
import HeaderBalanceProfileControls from 'layouts/header-balance-profile-controls';
import HeaderNavigation from 'layouts/header-navigation';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const { Header, Content } = Layout;

function L({ children }) {
  const { xs, sm } = useContext(WindowSizeContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const router = useRouter();

  // HACK
  useEffect(() => {
    const layoutEl = document.getElementsByClassName('ant-layout')[0];

    layoutEl.scrollTop = 0;
  }, [router.pathname]);

  return (
    <Layout
      className={`flex flex-col min-h-full h-full bg-transparent overflow-x-hidden ${isMenuOpen ? 'overflow-y-hidden' : 'overflow-y-scroll'}`}
    >
      {publicRuntimeConfig.blockchainNetwork !== 'matic' && (
        <div
          style={{ backgroundColor: '#1C2536' }}
          className="hidden md:flex flex-col items-center px-[4%] py-3"
        >
          <div className="max-w-[1200px] w-full flex items-center">
            <div className="ml-auto">
              <Link href="/app/admin/test-tokens">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <a
                  className="flex items-center font-bold"
                  onClick={() => mixpanel.track('👆🏼Claim TestToken Page')}
                >
                  Claim Test Tokens Now
                  <ChevronRightIcon className="ml-0.5 h-5 w-5" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      )}
      <Header className="sticky top-0 px-[4%] h-20 flex flex-col items-center bg-[#1E2024] z-[100]">
        <div className="max-w-[1200px] items-center flex justify-between w-full h-full">
          {publicRuntimeConfig.blockchainNetwork !== 'matic' && (
            <div className="flex items-center justify-center mr-8 gap-4">
              <Link href="/app/quests" passHref>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <a
                  className="flex"
                  onClick={() => mixpanel.track(
                    '👆🏼HomeLogo',
                    {},
                    { send_immediately: true },
                  )}
                >
                  <Image
                    alt="logo"
                    layout="fixed"
                    width={80}
                    height={24}
                    src="/static/images/lithium/lithium-mainnet-beta-mini.svg"
                    loading="eager"
                    quality={50}
                  />
                </a>
              </Link>
              <Link href="/app/admin" passHref>
                <Button ghost>
                  Admin
                </Button>
              </Link>
            </div>
          )}

          <div className="hidden md:flex items-center h-full grow shrink overflow-x-hidden">
            <HeaderNavigation />
          </div>
          {
            (xs || sm)
              ? <HeaderMobileMenuTrigger onMenuChange={setIsMenuOpen} />
              : <HeaderBalanceProfileControls />
          }
        </div>
      </Header>
      <Content
        className="flex-grow flex-shrink-0 flex flex-col"
        style={{ flexBasis: 'auto' }}
      >
        {children}
      </Content>
      <UserFooter />
    </Layout>
  );
}

L.propTypes = {
  children: element.isRequired,
};

export default L;
