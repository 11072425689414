import { QUEST_ALL } from 'api/endpoints';
import { get, post } from 'libs/axios';

/**
 * Get all quests sorted by status then date
 *
 * @param {object} param
 * @param {number} param.offset
 * @param {number} param.limit
 * @returns {object}
 */
async function getAllQuests() {
  const res = await get({
    url: QUEST_ALL,
  });

  return res;
}

/**
 * Get quests by graph ids
 *
 * @param {object} param
 * @param {string[]} param.graphIds - contractAddress-externalId
 * @returns {Promise<object>}
 */
async function getQuestsByGraphIds({ graphIds }) {
  const res = await post({
    url: `${QUEST_ALL}/graph-ids`,
    data: {
      graphIds,
    },
  });

  return res;
}

/**
 * GET quest detail by questId
 *
 * @function getQuest
 * @param {string} id
 * @returns {Promise<object>}
 */
async function getQuest({ id }) {
  const res = await get({
    url: `${QUEST_ALL}/${id}`,
  });

  return res;
}

/**
 * POST get all questItems order by displayOrder
 *
 * @function getQuestItems
 * @param {string} questId
 * @returns {Promise<object>}
 */
async function getQuestItems({
  questId,
}) {
  const res = await get({
    url: `${QUEST_ALL}/${questId}/quest-items`,
  });

  return res;
}

/**
 * POST get quests image
 *
 * @function getQuestsImg
 * @param {object} params
 * @param {string[]} params.questIds
 * @returns {Promise<object>}
 */
async function getQuestsImg({
  questIds,
}) {
  const res = await post({
    url: `${QUEST_ALL}/imgs`,
    data: {
      questIds,
    },
  });

  return res;
}

/**
 * GET get quest stats for landing page
 *
 * @function getQuestStats
 * @param {object} params
 * @returns {Promise<object>}
 */
async function getQuestStats() {
  const res = await get({
    url: `${QUEST_ALL}/stats`,
  });

  return res;
}

const funcs = {
  getAllQuests,
  getQuestsByGraphIds,

  getQuest,
  getQuestItems,
  getQuestsImg,
  getQuestStats,
};

export default funcs;
