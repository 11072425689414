import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Popover } from 'antd';
import { shortenString } from 'utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import numbro from 'numbro';

// componment
import ExpertiseIcon from 'components/icons/expertise-icon';
import HeaderProfileMenu from 'layouts/header-profile-menu';
import LithIcon from 'components/icons/lith-icon';
import MaticIcon from 'components/icons/matic-icon';

// hooks
import { useClearFinishedTransaction } from 'stores/actions/transaction-actions';
import { useHasPendingTransaction, useTransaction, useUnseenTransaction } from 'stores/selectors/transaction-selectors';
import LoadingCircleStatus from 'components/loading-circle-status';
import useLithBalanceQuery from 'hooks/queries/use-lith-balance-query';
import useMaticBalanceQuery from 'hooks/queries/use-matic-balance-query';
import useRpBalanceQuery from 'hooks/queries/use-rp-balance-query';
import useUserQuery from 'hooks/queries/use-user-query';

function HeaderBalanceProfileControls() {
  const menuContainerRef = useRef(null);
  const lastTransaction = useRef({});
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const resetLoadingId = useRef(null);
  const [loadingType, setLoadingType] = useState(null);

  const { user } = useUserQuery();

  const { lithBalance } = useLithBalanceQuery('0');
  const { rpBalance } = useRpBalanceQuery('0');
  const { maticBalance } = useMaticBalanceQuery('0');

  const hasPending = useHasPendingTransaction();
  const unseenTransaction = useUnseenTransaction();
  const transaction = useTransaction();
  const clearFinished = useClearFinishedTransaction();

  const numUnseenTransaction = _.size(unseenTransaction);

  const shortenedAddress = shortenString(user.publicAddress);

  useEffect(() => {
    Object.keys(transaction).forEach((hash) => {
      const current = transaction[hash];
      const last = lastTransaction.current[hash];

      if (current.status !== last?.status) {
        setLoadingType(current.status);

        if (resetLoadingId.current) clearTimeout(resetLoadingId.current);
        resetLoadingId.current = setTimeout(() => setLoadingType(null), 2000);
      }
    });

    lastTransaction.current = transaction;
  }, [transaction]);

  const handleProfileMenuVisibleChange = useCallback((visible) => {
    setIsProfileVisible(visible);

    if (!visible) {
      setTimeout(() => clearFinished(), 500);
    }
  }, [clearFinished]);

  return (
    <div className="ml-auto flex items-center gap-4">
      <div className="flex items-center bg-[#010211] rounded-full border border-[#5B5C6C] h-10 px-3">
        <MaticIcon darkMode withCircle />
        <span className="font-bold text-white pl-1 pr-5">
          {numbro(maticBalance).format({
            thousandSeparated: true,
            mantissa: 2,
          })}
        </span>
        <LithIcon darkMode withCircle />
        <span className="font-bold text-white pl-1 pr-5">
          {numbro(lithBalance).format({
            thousandSeparated: true,
            mantissa: 0,
          })}
        </span>
        <ExpertiseIcon width={16} height={16} />
        <span className="font-bold text-white pl-1">
          {numbro(rpBalance).format({
            thousandSeparated: true,
            mantissa: 0,
          })}
        </span>
      </div>

      <Popover
        placement="bottomRight"
        getPopupContainer={() => menuContainerRef.current}
        content={
          <HeaderProfileMenu onClose={() => setIsProfileVisible(false)} />
        }
        trigger="click"
        open={isProfileVisible}
        onOpenChange={handleProfileMenuVisibleChange}
        destroyTooltipOnHide
        className="z-[101]"
      >
        <button type="button" className="flex relative items-center">
          <LoadingCircleStatus
            type={loadingType ?? (hasPending ? 'pending' : null)}
          >
            <div className="w-10 h-10 box-border rounded-full border border-neutral-600 z-10">
              <Image
                alt="Profile avatar"
                width={38}
                height={38}
                src={'/static/images/profilepage-avatar.svg'}
              />
            </div>
          </LoadingCircleStatus>

          <span className="relative rounded-full py-1 pl-12 pr-3 -ml-10 flex items-center text-base text-white bg-neutral-800">
            <Text className={`transition-all ${isProfileVisible ? 'text-current' : 'text-white'}`}>{user.name ?? shortenedAddress}</Text>
            <ChevronDownIcon className={`ml-0.5 h-5 w-5 transition-all ${isProfileVisible ? 'text-current rotate-180' : 'text-white rotate-0'}`} />
            {
              numUnseenTransaction > 0 && (
                <div className="absolute bottom-full left-full translate-y-1/2 -translate-x-1/2 text-xs font-medium bg-[#F5222D] rounded-full w-5 h-5 flex items-center justify-center">
                  {numUnseenTransaction > 1 && (numUnseenTransaction > 9 ? '9+' : numUnseenTransaction)}
                </div>
              )
            }
          </span>

        </button>

        <div ref={menuContainerRef} className="ant-popover-no-style" />
      </Popover>
    </div>
  );
}

export default HeaderBalanceProfileControls;
