import { number, oneOfType, string } from 'prop-types';
import { useState } from 'react';

function DiscordIcon({ size = 24 }) {
  const [isHover, setHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="36"
        height="36"
        rx="18"
        fill={isHover ? '#FCFCFC' : 'none'}
        fillOpacity="0.9"
      />
      <g clipPath="url(#clip0_3236_98453)">
        <path
          d="M24.9308 11.7637C23.6561 11.1788 22.2891 10.7478 20.8599 10.501C20.8338 10.4963 20.8078 10.5082 20.7944 10.532C20.6186 10.8447 20.4239 11.2526 20.2875 11.5732C18.7503 11.3431 17.2209 11.3431 15.7152 11.5732C15.5788 11.2455 15.377 10.8447 15.2004 10.532C15.187 10.509 15.161 10.4971 15.135 10.501C13.7065 10.7471 12.3396 11.178 11.0641 11.7637C11.053 11.7684 11.0435 11.7764 11.0373 11.7867C8.44443 15.6603 7.73415 19.4387 8.08259 23.1703C8.08417 23.1886 8.09441 23.206 8.1086 23.2171C9.81928 24.4734 11.4764 25.2361 13.1027 25.7416C13.1287 25.7496 13.1563 25.74 13.1728 25.7186C13.5575 25.1933 13.9005 24.6393 14.1945 24.0568C14.2119 24.0227 14.1953 23.9822 14.1598 23.9687C13.6159 23.7624 13.0979 23.5108 12.5997 23.2251C12.5603 23.2021 12.5572 23.1457 12.5934 23.1187C12.6982 23.0402 12.8031 22.9584 12.9032 22.8759C12.9213 22.8608 12.9466 22.8576 12.9679 22.8671C16.241 24.3615 19.7846 24.3615 23.0191 22.8671C23.0404 22.8568 23.0656 22.86 23.0845 22.8751C23.1846 22.9576 23.2895 23.0402 23.3951 23.1187C23.4314 23.1457 23.429 23.2021 23.3896 23.2251C22.8914 23.5163 22.3734 23.7624 21.8287 23.9679C21.7932 23.9814 21.7775 24.0227 21.7948 24.0568C22.0952 24.6385 22.4381 25.1924 22.8157 25.7178C22.8315 25.74 22.8598 25.7496 22.8859 25.7416C24.5201 25.2361 26.1771 24.4734 27.8878 23.2171C27.9028 23.206 27.9122 23.1894 27.9138 23.1711C28.3308 18.857 27.2153 15.1096 24.9568 11.7875C24.9513 11.7764 24.9418 11.7684 24.9308 11.7637ZM14.6833 20.8982C13.6979 20.8982 12.8859 19.9935 12.8859 18.8824C12.8859 17.7713 13.6821 16.8666 14.6833 16.8666C15.6923 16.8666 16.4964 17.7793 16.4807 18.8824C16.4807 19.9935 15.6844 20.8982 14.6833 20.8982ZM21.3289 20.8982C20.3435 20.8982 19.5315 19.9935 19.5315 18.8824C19.5315 17.7713 20.3277 16.8666 21.3289 16.8666C22.338 16.8666 23.1421 17.7793 23.1263 18.8824C23.1263 19.9935 22.338 20.8982 21.3289 20.8982Z"
          fill={isHover ? '#5865F2' : '#FCFCFC'}
        />
      </g>
      <defs>
        <clipPath id="clip0_3236_98453">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>

  );
}

DiscordIcon.propTypes = {
  size: oneOfType([number, string]),
};

export default DiscordIcon;
