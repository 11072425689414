import {
  API_SUBMISSION,
} from 'api/endpoints';
import {
  del,
  patch,
  post,
} from 'libs/axios';

async function postSubmission({ data }) {
  const res = await post({
    url: API_SUBMISSION,
    data,
  });

  return res;
}

async function patchSubmission({ id, data }) {
  const res = await patch({
    url: `${API_SUBMISSION}/${id}`,
    data,
  });

  return res;
}

async function deleteSubmission({ id }) {
  const res = await del({
    url: `${API_SUBMISSION}/${id}`,
    data: {
      id,
    },
  });

  return res;
}

const funcs = {
  postSubmission,
  patchSubmission,
  deleteSubmission,
};

export default funcs;
