import { COLLECTION_MAPPING } from 'libs/react-query/constants';
import { collectionApi } from 'api/fetchers';
import { useQuery } from 'react-query';
import _ from 'lodash';

function useCollectionMappingQuery() {
  const {
    data,
    ...values
  } = useQuery(
    [COLLECTION_MAPPING],
    async () => {
      const { data: records } = await collectionApi.getCollectionMapping();

      const SLUG_COLLECTION_MAPPING = _.reduce(
        records,
        (res, record) => ({
          ...res,
          [record.slug]: record.name,
        }),
        {},
      );

      const COLLECTION_SLUG_MAPPING = _.invert(SLUG_COLLECTION_MAPPING);

      const SLUGS = _.map(records, (value) => value.slug);

      return ({
        SLUG_COLLECTION_MAPPING,
        COLLECTION_SLUG_MAPPING,
        SLUGS,
        COLLECTIONS: records,
      });
    },
    {
      staleTime: 1000 * 60,
    },
  );

  return {
    data,
    ...values,
  };
}

export default useCollectionMappingQuery;
