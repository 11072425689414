import { number, oneOfType, string } from 'prop-types';
import { useState } from 'react';

function MediumIcon({ size = 24 }) {
  const [isHover, setHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      <rect
        width="36"
        height="36"
        rx="18"
        fill={isHover ? '#FCFCFC' : 'none'}
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0435 17.5217C19.0435 20.019 17.019 22.0435 14.5217 22.0435C12.0245 22.0435 10 20.019 10 17.5217C10 15.0245 12.0245 13 14.5217 13C17.019 13 19.0435 15.0245 19.0435 17.5217ZM23.913 17.6957C23.913 19.9048 22.9787 21.6957 21.8261 21.6957C20.6735 21.6957 19.7391 19.9048 19.7391 17.6957C19.7391 15.4865 20.6735 13.6957 21.8261 13.6957C22.9787 13.6957 23.913 15.4865 23.913 17.6957ZM25.1304 21.3478C25.6107 21.3478 26 19.7127 26 17.6957C26 15.6786 25.6107 14.0435 25.1304 14.0435C24.6502 14.0435 24.2609 15.6786 24.2609 17.6957C24.2609 19.7127 24.6502 21.3478 25.1304 21.3478Z"
        fill={isHover ? '#000000' : '#FCFCFC'}
      />

    </svg>

  );
}

MediumIcon.propTypes = {
  size: oneOfType([number, string]),
};

export default MediumIcon;
