import _ from 'lodash';
import useSubmissionsStore from 'stores/submissions-store';
import useUserQuery from 'hooks/queries/use-user-query';

function usePublicAddressSelector(submissionsSelector) {
  const { user, isLoggedIn } = useUserQuery();

  return useSubmissionsStore(
    isLoggedIn
      ? (state) => submissionsSelector(state.submissions[user.publicAddress] ?? {})
      : () => ({}),
  );
}

export function useAllSubmissions() {
  return usePublicAddressSelector(
    (submissions) => submissions.quests,
  );
}

export function useClaimableSubmissions() {
  return usePublicAddressSelector(
    (submissions) => _.pickBy(
      submissions.quests,
      (s) => (s.submissionGroup === 'CLAIMABLE' && !s.hasSeen),
    ),
  );
}

export function useClaimedSubmissions() {
  return usePublicAddressSelector(
    (submissions) => _.pickBy(
      submissions.quests,
      (s) => (s.submissionGroup === 'CLAIMED' && !s.hasSeen),
    ),
  );
}

export function useCancelledSubmissions() {
  return usePublicAddressSelector(
    (submissions) => _.pickBy(
      submissions.quests,
      (s) => (
        _.includes(
          ['REFUNDABLE', 'REFUNDED'],
          s.submissionGroup,
        )
        && !s.hasSeen
      ),
    ),
  );
}

export function useUnseenSubmissions() {
  return usePublicAddressSelector(
    (submissions) => _.pickBy(
      submissions.quests,
      (s) => (!s.hasSeen),
    ),
  );
}
