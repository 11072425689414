import {
  chooseAbi,
  chooseContractAddress,
  chooseInfuraProvider,
  chooseProvider,
} from 'smart-contracts';
import { createContext, useMemo } from 'react';
import { ethers } from 'ethers';
import { node } from 'prop-types';

const ContractsContext = createContext({});

export function ContractsContextProvider({ children }) {
  const value = useMemo(() => {
    if (typeof window !== 'undefined' && window.ethereum) {
      const infuraProvider = chooseInfuraProvider();
      const provider = chooseProvider();
      const signer = provider.getSigner();

      // read only use infura
      const lithTokenReadContract = new ethers.Contract(
        chooseContractAddress('LITHIUM_TOKEN'),
        chooseAbi('LITHIUM_TOKEN'),
        infuraProvider,
      );
      const micTokenReadContract = new ethers.Contract(
        chooseContractAddress('REPUTATION_TOKEN'),
        chooseAbi('REPUTATION_TOKEN'),
        infuraProvider,
      );
      const enquiryPoolReadContractV1 = new ethers.Contract(
        chooseContractAddress('ENQUIRY_POOL_V1'),
        chooseAbi('ENQUIRY_POOL_V1'),
        infuraProvider,
      );
      const enquiryPoolReadContractV2 = new ethers.Contract(
        chooseContractAddress('ENQUIRY_POOL_V2'),
        chooseAbi('ENQUIRY_POOL_V2'),
        infuraProvider,
      );

      // write, use metamask
      const lithTokenContract = new ethers.Contract(
        chooseContractAddress('LITHIUM_TOKEN'),
        chooseAbi('LITHIUM_TOKEN'),
        signer,
      );
      const micTokenContract = new ethers.Contract(
        chooseContractAddress('REPUTATION_TOKEN'),
        chooseAbi('REPUTATION_TOKEN'),
        signer,
      );
      const enquiryPoolContractV1 = new ethers.Contract(
        chooseContractAddress('ENQUIRY_POOL_V1'),
        chooseAbi('ENQUIRY_POOL_V1'),
        signer,
      );
      const enquiryPoolContractV2 = new ethers.Contract(
        chooseContractAddress('ENQUIRY_POOL_V2'),
        chooseAbi('ENQUIRY_POOL_V2'),
        signer,
      );
      const enquiryPoolInterfaceV1 = new ethers.utils.Interface(chooseAbi('ENQUIRY_POOL_V1'));
      const enquiryPoolInterfaceV2 = new ethers.utils.Interface(chooseAbi('ENQUIRY_POOL_V2'));

      const enquiryPoolAddressV1 = chooseContractAddress('ENQUIRY_POOL_V1');
      const enquiryPoolAddressV2 = chooseContractAddress('ENQUIRY_POOL_V2');

      const enquiryPoolContractLookup = {
        [enquiryPoolAddressV1]: enquiryPoolContractV1,
        [enquiryPoolAddressV2]: enquiryPoolContractV2,
      };

      return {
        provider,
        signer,

        lithTokenReadContract,
        micTokenReadContract,
        enquiryPoolReadContractV1,
        enquiryPoolReadContractV2,

        lithTokenContract,
        micTokenContract,

        enquiryPoolContractV1,
        enquiryPoolContractV2,

        enquiryPoolInterfaceV1,
        enquiryPoolInterfaceV2,

        enquiryPoolContractLookup,
      };
    }

    return {};
  }, []);

  return (
    <ContractsContext.Provider value={value}>
      {children}
    </ContractsContext.Provider>
  );
}

ContractsContextProvider.propTypes = {
  children: node,
};

export default ContractsContext;
