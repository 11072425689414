import { createContext, useEffect, useMemo, useState } from 'react';
import { node } from 'prop-types';

const WindowSizeContext = createContext({});

export function WindowSizeContextProvider({ children }) {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const value = useMemo(() => ({
    ...windowSize,
    xs: windowSize.width < 640,
    sm: windowSize.width >= 640 && windowSize.width < 768,
    md: windowSize.width >= 768 && windowSize.width < 1024,
    lg: windowSize.width >= 1024 && windowSize.width < 1280,
    xl: windowSize.width >= 1280 && windowSize.width < 1536,
    xl2: windowSize.width >= 1536,
  }), [windowSize]);

  return (
    <WindowSizeContext.Provider value={value}>
      {children}
    </WindowSizeContext.Provider>
  );
}

WindowSizeContextProvider.propTypes = {
  children: node,
};

export default WindowSizeContext;
