import { Button, Layout } from 'antd';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { element } from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import mixpanel from 'libs/mixpanel';

// components
import HeaderBalanceProfileControls from 'layouts/header-balance-profile-controls';
import HeaderMobileMenuTrigger from 'layouts/header-mobile-menu-trigger';
import HeaderNavigation from 'layouts/header-navigation';
import HeaderSocialConnectControls from 'layouts/header-socials-connect-controls';
import UserFooter from 'layouts/footer';

// hooks
import ExpertiseIcon from 'components/icons/expertise-icon';
import WindowSizeContext from 'contexts/window-size';
import useUserQuery from 'hooks/queries/use-user-query';

const { Header, Content } = Layout;

function L({ children }) {
  const { isLoggedIn } = useUserQuery();
  const { xs, sm } = useContext(WindowSizeContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const router = useRouter();

  // HACK
  useEffect(() => {
    const layoutEl = document.getElementsByClassName('ant-layout')[0];

    layoutEl.scrollTop = 0;
  }, [router.pathname]);

  return (
    <Layout
      style={{
        minHeight: '-webkit-fill-available',
      }}
      className={`flex flex-col min-h-full h-full bg-transparent overflow-x-hidden ${isMenuOpen ? 'overflow-y-hidden' : 'overflow-y-scroll'}`}
    >
      <div className="top-0 hidden md:flex px-[4%] flex-col items-center bg-[#010211] w-full z-[110]">
        <div className="max-w-[1200px] w-full flex items-center">
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center font-semibold text-lg text-white gap-2">
              <span>Product Update: New RP Claim</span>
              <ExpertiseIcon />
            </div>
            <Button
              className="flex items-center font-bold"
              onClick={() => {
                mixpanel.track('👆🏼Claim TestToken Page');

                const searchParams = new URLSearchParams();
                searchParams.append('redirection-path', '/app/my-profile');

                return isLoggedIn
                  ? router.push('/app/my-profile')
                  : router.push(`/app/connect?${searchParams.toString()}`);
              }}
              type="text"
            >
              Claim Now
              <ChevronRightIcon className="ml-0.5 h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>
      <Header className="sticky top-0 px-[4%] h-20 flex flex-col items-center bg-[#1E2024] z-[100]">
        <div className="max-w-[1200px] py-2 items-center flex w-full h-full">
          <Link href="/app/quests" passHref>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <a
              className="flex mr-8"
              onClick={() => mixpanel.track(
                '👆🏼HomeLogo',
                {},
                { send_immediately: true },
              )}
            >
              <Image
                alt="logo"
                layout="fixed"
                width={80}
                height={24}
                src="/static/images/lithium/lithium-mainnet-beta-mini.svg"
                loading="eager"
                quality={50}
              />
            </a>
          </Link>
          <div className="hidden md:flex items-center h-full grow shrink overflow-x-hidden">
            <HeaderNavigation />
          </div>
          {
            (xs || sm)
              ? <HeaderMobileMenuTrigger onMenuChange={setIsMenuOpen} />
              : isLoggedIn ? (
                <HeaderBalanceProfileControls />
              ) : (
                <HeaderSocialConnectControls showConnect />
              )
          }
        </div>
      </Header>
      <Content className="flex-grow flex-shrink-0 flex flex-col">
        {children}
      </Content>
      <UserFooter />
    </Layout>
  );
}

L.propTypes = {
  children: element.isRequired,
};

export default L;
