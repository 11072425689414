import { animated, useSpring } from 'react-spring';
import { bool } from 'prop-types';
import { useRef } from 'react';

export default function IsLoading({
  darkMode = true,
}) {
  const flip = useRef(false);
  const fade = useSpring({
    from: {
      opacity: 0.7,
    },
    to: {
      opacity: 1,
    },
    reset: true,
    delay: 1000,
    reverse: flip,
    onRest: () => { flip.current = !flip.current; }, // no longer mounted if not rendered
  });

  return (
    <section
      className="px-[4%] py-4 h-full w-full flex flex-col items-center"
      style={{
        backgroundColor: darkMode ? '#000000' : 'transparent',
      }}
    >
      <div className="max-w-[1200px] w-full">
        <animated.div className="bg-[#2E2F3A] my-8 rounded-lg w-1/2 h-12" style={fade} />
        <animated.div className="bg-[#2E2F3A] my-4 rounded-lg w-1/3 h-6" style={fade} />
        <animated.div className="bg-[#2E2F3A] mt-4 mb-8 rounded-lg w-1/2 h-6" style={fade} />
        <animated.div className="bg-[#2E2F3A] my-4 rounded-lg w-full h-32" style={fade} />
        <animated.div className="bg-[#2E2F3A] my-8 rounded-lg w-full h-24" style={fade} />
        <animated.div className="bg-[#2E2F3A] my-8 rounded-lg w-full h-24" style={fade} />
      </div>
    </section>
  );
}

IsLoading.propTypes = {
  darkMode: bool,
};
