import { oneOf } from 'prop-types';
import Image from 'next/image';

function ExpertiseIcon({
  type,
  ...otherProps
}) {
  switch (type) {
    default:
      return (
        <Image
          width={24}
          height={24}
          layout="fixed"
          alt="reputation"
          src="/static/images/icons/diamond.png"
          {...otherProps}
        />
      );
  }
}

ExpertiseIcon.propTypes = {
  type: oneOf([
  ]),
};

export default ExpertiseIcon;
