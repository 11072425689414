/* eslint-disable max-classes-per-file */
import { waitRandom } from 'utils';

/**
 * This error is thrown if the function is cancelled before completing
 */
class CancelledError extends Error {
  isCancelledError = true

  constructor() {
    super('Cancelled');
  }
}

/**
 * Throw this error if the function should retry
 */
export class RetryableError extends Error {
  isRetryableError = true
}

/**
 * Retries the function that returns the promise
 * until the promise successfully resolves up to n retries
 *
 * @param fn function to retry
 * @param n how many times to retry
 * @param minWait min wait between retries in ms
 * @param maxWait max wait between retries in ms
 */
export function retry(
  fn,
  { n, minWait, maxWait },
) {
  let completed = false;
  let rejectCancelled;
  // eslint-disable-next-line no-async-promise-executor
  const promise = new Promise(async (resolve, reject) => {
    let num = n;
    rejectCancelled = reject;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      let result;
      try {
        // eslint-disable-next-line no-await-in-loop
        result = await fn();

        if (!completed) {
          completed = true;
          resolve(result);
        }
        break;
      } catch (error) {
        if (completed) {
          break;
        }
        if (num <= 0 || !error.isRetryableError) {
          completed = true;
          reject(error);
          break;
        }
        num -= 1;
      }
      // eslint-disable-next-line no-await-in-loop
      await waitRandom(minWait, maxWait);
    }
  });

  return {
    promise,
    cancel: () => {
      if (completed) return;
      completed = true;
      rejectCancelled(new CancelledError());
    },
  };
}
