import 'antd/dist/antd.css';
import 'styles/antd-override.less';
import 'styles/fonts.css';
import 'styles/globals.css';

import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { func, object } from 'prop-types';
import { useRouter } from 'next/router';
import Base from 'pages/_base';
import Bugsnag from 'libs/bugsnag';
import ErrorView from 'components/error-view';
import Head from 'next/head';
import React, { useState } from 'react';
import Script from 'next/script';
import getConfig from 'next/config';

import ClaimTokenUpdater from 'components/updater/claim-token-updater';
import QuestSubmissionUpdater from 'components/updater/quest-submission-updater';

import { BlockNumberProvider } from 'hooks/use-block-number';

import { ContractsContextProvider } from 'contexts/contracts';
import { WindowSizeContextProvider } from 'contexts/window-size';

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const { publicRuntimeConfig } = getConfig();
const { gaKey } = publicRuntimeConfig;

function Updaters() {
  return (
    <>
      <QuestSubmissionUpdater />
      <ClaimTokenUpdater />
    </>
  );
}

function App({ Component, pageProps }) {
  const router = useRouter();
  const { pathname } = router;

  const [queryClient] = useState(() => new QueryClient());
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gaKey}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaKey}');
        `}
      </Script>
      <Head>
        <title>Lithium Finance</title>
        {pathname !== '/' && (
          <meta name="viewport" content="width=1024" />
        )}
        <meta name="description" content="Lithium Finance brings market participants together through economic incentives to estimate pricing for illiquid assets on demand." />
        <meta property="og:site_name" content="Lithium Finance" />
        <meta property="og:title" content="Lithium Finance | Decentralized NFT Pricing powered by Collective Intelligence" />
        <meta property="og:url" content={publicRuntimeConfig.baseUrl} />
        <meta property="og:description" content="Lithium Finance brings market participants together through economic incentives to estimate pricing for illiquid assets on demand." />
        <meta property="og:image" itemProp="image" content={`${publicRuntimeConfig.baseUrl}/static/images/lithium-finance-socials-banner.png`} />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content="@LithiumFinance" />
        <meta property="twitter:creator" content="@LithiumFinance" />
        <meta property="twitter:title" content="Lithium Finance | Decentralized NFT Pricing powered by Collective Intelligence" />
        <meta property="twitter:description" content="Lithium Finance brings market participants together through economic incentives to estimate pricing for illiquid assets on demand." />
        <link rel="icon" href="/favicons/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="preload"
          href="/fonts/Mukta-ExtraLight.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Mukta-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Mukta-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Mukta-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Mukta-SemiBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Mukta-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Mukta-ExtraBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Thin.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-ExtraLight.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-SemiBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-ExtraBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Black.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-Thin.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-ExtraLight.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-SemiBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-ExtraBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/WorkSans-Italic-Black.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <WindowSizeContextProvider>
              <ContractsContextProvider>
                <BlockNumberProvider>
                  <Base pageProps={pageProps}>
                    {getLayout(<Component {...pageProps} />)}
                  </Base>
                  <Updaters />
                </BlockNumberProvider>
                <ReactQueryDevtools />
              </ContractsContextProvider>
            </WindowSizeContextProvider>
          </Hydrate>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}

App.propTypes = {
  Component: func.isRequired,
  pageProps: object, // eslint-disable-line react/forbid-prop-types, react/require-default-props
};

export default App;
