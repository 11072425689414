import { QUEST_SUBMISSIONS_USER } from 'libs/react-query/constants';
import { getGraphParticipatedQuestData } from 'api/subgraphs/quest-submission';
import { questApi, questSubmissionApi, transactionApi } from 'api/fetchers';
import { useQuery } from 'react-query';
import _ from 'lodash';

// hooks
import { mergeQuestsWithData } from 'hooks/queries/quest-submission-helper';
import useUserQuery from 'hooks/queries/use-user-query';

// get questSubmissions with all status, filter them on Frontend
export default function useQuestSubmissionsQuery() {
  const { user } = useUserQuery();

  const {
    data,
    ...values
  } = useQuery(
    [QUEST_SUBMISSIONS_USER],
    async ({ pageParam = 0 }) => {
      // subgraph quest data
      const graphQuestData = await getGraphParticipatedQuestData({
        publicAddress: user?.publicAddress,
      });

      // db quest data
      const { data: quests } = await questApi.getQuestsByGraphIds({
        graphIds: graphQuestData.map((d) => (`${d.graphContractAddress}-${d.externalId}`)),
      });

      // db questSubmissions data
      const { data: dbQuestSubmissions } = await questSubmissionApi.getQuestSubmissionsByQuestIds({
        userId: user.id,
        questIds: quests.map((a) => (a.id)),
      });

      // db questItemSubmissions data
      const {
        data: dbQuestItemSubmissions,
      } = await questSubmissionApi.getQuestItemSubmissionsByQuestIds({
        userId: user.id,
        questIds: quests.map((a) => (a.id)),
      });

      // db transaction data
      const dbTransactions = await transactionApi.postTransactionsBySignatures({
        signatures: graphQuestData.map((a) => (a.graphSignature)),
      });

      // filter out INACTIVE quests
      const activeQuests = quests.filter((q) => ['ACTIVE', 'CLOSED', 'DISTRIBUTED'].find((s) => q.status === s));

      const assets = await Promise.all(_.map(activeQuests, async (quest) => {
        const res = await questApi.getQuestItems({ questId: quest.id });

        return {
          [quest.id]: _.keyBy(
            res.data.map((item) => ({
              itemId: item.id,
              tokenId: item.tokenId,
              imgUrl: item.asset.imgUrl,
            })),
            'tokenId',
          ),
        };
      }));

      const assetsLookup = _.reduce(
        assets,
        (res, asset) => ({
          ...res,
          [_.keys(asset)[0]]: _.values(asset)[0],
        }),
        {},
      );

      // merge all stuff here (create lookup, spread those data)
      const mergedQuests = await mergeQuestsWithData({
        graphQuestData,
        dbQuests: quests,
        dbQuestSubmissions,
        dbQuestItemSubmissions,
        dbTransactions: dbTransactions.data,
        imgs: assetsLookup,
      });

      return {
        offset: pageParam,
        data: mergedQuests,
      };
    },
    {
      enabled: Boolean(user),
      staleTime: 1000 * 60,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  );

  return {
    questSubmissionsPages: data?.data,
    ...values,
  };
}
