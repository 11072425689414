import { GraphQLClient } from 'graphql-request';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const {
  serverUrl,
  blockchainNetwork,
  graphEnquiryPoolUrlLookup,
} = publicRuntimeConfig;

// TODO: Move to context when for we enabled empty chain interaction
export const subgraphClient = new GraphQLClient(
  graphEnquiryPoolUrlLookup[blockchainNetwork],
  { headers: {} },
);

const graphQLClient = new GraphQLClient(`${serverUrl}/graphql`, {
  credentials: 'include',
  mode: 'cors', // TODO: Verify if this is needed in staging / production
});

export default graphQLClient;
