import { POST_LOGIN, POST_LOGOUT, POST_NONCE } from 'api/endpoints';
import { message } from 'antd';
import { post } from 'libs/axios';
import { toHex } from 'utils';
import detectEthereumProvider from '@metamask/detect-provider';

async function login() {
  const provider = await detectEthereumProvider();
  if (provider) {
    const addresses = await provider.request({ method: 'eth_requestAccounts' });
    const nonceRes = await post({
      url: POST_NONCE,
      data: { publicAddress: addresses[0] },
    });

    const signature = await provider.request({
      method: 'personal_sign',
      params: [
        `0x${toHex(nonceRes.data.nonce)}`,
        window.ethereum.selectedAddress,
      ],
    });

    const res = await post({
      url: POST_LOGIN,
      data: { signature, publicAddress: addresses[0] },
    });

    return res;
  }

  return message.error('This is an error!');
}

async function logout() {
  await post({ url: POST_LOGOUT });

  await message.success("You've logged out!", 0.5);
}

export { login, logout };
