/**
 * Simple error component for Bugsnag
 */
import { Button, Typography } from 'antd';

const { Title, Paragraph } = Typography;

function onClick() {
  window.location.replace('/app/quests');
}

function ErrorView() {
  return (
    <section className="h-screen flex flex-col justify-center items-center bg-white">
      <div className="flex flex-col items-center">
        <Title level={1}>Sorry, something went wrong :/</Title>
        <Paragraph>Please refresh the page or contact support</Paragraph>
      </div>
      <Button type="primary" onClick={onClick}>
        Back to Home
      </Button>
    </section>
  );
}

ErrorView.propTypes = {};

export default ErrorView;
