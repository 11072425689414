import { dayjs } from 'libs/dayjs';
import { persist } from 'zustand/middleware';
import _ from 'lodash';
import create from 'zustand';
import produce from 'immer';

const logger = (config) => (set, get, api) =>
  config(
    (...args) => {
      console.debug('Applying', args);
      set(...args);
      console.debug('Next State', get());
    },
    get,
    api,
  );

// create a persist store with the logger middleware
const useSubmissionsStore = create(
  persist(
    logger(
      (set) => ({
        // init state
        // submissions: { publicAddress: { quests: [{ submissionGroup, hasSeen, lastSeen }] } }
        submissions: {},

        // add new submission
        // actions
        add: ({ publicAddress, questIds, submissionGroup, ...values }) => {
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);

              _.forEach(questIds, (questId) => {
                newState.submissions[publicAddress] ??= {};
                newState.submissions[publicAddress].quests ??= {};
                newState.submissions[publicAddress].quests[questId] = {
                  submissionGroup,
                  hasSeen: false,
                  ...values,
                };
              });

              return newState;
            }),
          );
        },

        // mark quests as seen
        markAsSeen: ({ publicAddress, questIds, submissionGroup }) =>
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);

              _.forEach(questIds, (questId) => {
                newState.submissions[publicAddress].quests[questId] ??= {};
                newState.submissions[publicAddress].quests[questId] = {
                  hasSeen: true,
                  lastSeen: dayjs().unix(),
                  submissionGroup,
                };
              });

              return newState;
            }),
          ),
      }),
    ),
  ),
  // name of the storage (must be unique)
  { name: 'submissions' },
);

export default useSubmissionsStore;
