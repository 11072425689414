import {
  abiLookup,
  allAbiLookup,
} from 'smart-contracts/abis';
import { ethers } from 'ethers';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};

const {
  blockchainNetwork,

  enquiryPoolAddressLookupV1,
  enquiryPoolAddressLookupV2,

  lithiumTokenAddressLookup,
  reputationTokenAddressLookup,
  polygonTokenAddressLookup,
} = publicRuntimeConfig;

const explorerLookup = {
  matic: 'https://polygonscan.com',
  mumbai: 'https://mumbai.polygonscan.com',
  hardhat: '',
};

const addressLookup = {
  ENQUIRY_POOL_V1: enquiryPoolAddressLookupV1,
  ENQUIRY_POOL_V2: enquiryPoolAddressLookupV2,

  LITHIUM_TOKEN: lithiumTokenAddressLookup,
  REPUTATION_TOKEN: reputationTokenAddressLookup,
  POLYGON_TOKEN: polygonTokenAddressLookup,
};

export const ETHEREUM_NETWORK = {
  mainnet: 'homestead',
  ropsten: 'ropsten',
  rinkeby: 'rinkeby',
  goerli: 'goerli',
  kovan: 'kovan',
};

export const POLYGON_NETWORK = {
  matic: 'matic',
  mumbai: 'maticmum',
  hardhat: 'http://localhost:8545',
};

export function identifyNetwork(chainId) {
  switch (chainId) {
    case '80001':
      return 'Mumbai';
    case '137':
      return 'Matic';
    case '31337':
      return 'Hardhat';
    default:
      return 'Hardhat';
  }
}

// export function ethereumNetwork() {
//   return publicRuntimeConfig.appEnvironment === 'production'
//     ? ETHEREUM_NETWORK.mainnet
//     : ETHEREUM_NETWORK.ropsten;
// }

export function chooseNetwork() {
  return POLYGON_NETWORK[blockchainNetwork];
}

export function chooseExplorer() {
  return explorerLookup[blockchainNetwork];
}

export function chooseInfuraProvider() {
  return blockchainNetwork === 'hardhat'
    ? new ethers.providers.Web3Provider(
      window.ethereum,
    )
    : new ethers.providers.InfuraProvider(
      chooseNetwork(),
      {
        projectId: publicRuntimeConfig.infuraKey,
      },
    );
}

export function chooseProvider() {
  return blockchainNetwork === 'hardhat'
    ? new ethers.providers.Web3Provider(
      window.ethereum,
    )
    : new ethers.providers.Web3Provider(
      window.ethereum,
      chooseNetwork(),
    );
}

export function chooseAbi(type) {
  return abiLookup[type];
}

export function chooseAbiByAddress(address) {
  return allAbiLookup[address];
}

export function chooseContractAddress(type) {
  return addressLookup[type][blockchainNetwork];
}
