import { bool, number } from 'prop-types';
import Image from 'next/image';

function MaticIcon({
  // align with Charlie for darkMode and circle
  darkMode = false,
  withCircle = false,
  size = 24,
  ...otherProps
}) {
  return (
    <Image
      width={size}
      height={size}
      layout="fixed"
      alt={`MATIC Icon ${darkMode ? 'Light' : 'Dark'}${withCircle ? ' With Circle' : ''}`}
      src={`/static/images/icons/matic-icons/matic-${darkMode ? 'light' : 'dark'}${withCircle ? '-circle' : ''}.svg`}
      {...otherProps}
    />
  );
}

MaticIcon.propTypes = {
  darkMode: bool,
  withCircle: bool,
  size: number,
};

export default MaticIcon;
