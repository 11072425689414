import { number, oneOfType, string } from 'prop-types';
import { useState } from 'react';

function TwitterIcon({ size = 24 }) {
  const [isHover, setHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="36"
        height="36"
        rx="18"
        fill={isHover ? '#FCFCFC' : 'none'}
        fillOpacity="0.9"
      />
      <path
        d="M14.2918 26.2506C21.8371 26.2506 25.9652 19.9979 25.9652 14.5772C25.9652 14.4014 25.9613 14.2217 25.9535 14.0459C26.7566 13.4652 27.4496 12.7458 28 11.9217C27.2521 12.2544 26.458 12.4718 25.6449 12.5662C26.5011 12.053 27.1421 11.2468 27.4492 10.2971C26.6438 10.7744 25.763 11.1111 24.8445 11.2928C24.2257 10.6352 23.4075 10.1999 22.5164 10.054C21.6253 9.9081 20.711 10.0598 19.9148 10.4857C19.1186 10.9116 18.4848 11.5879 18.1115 12.41C17.7382 13.2322 17.6462 14.1545 17.8496 15.0342C16.2187 14.9524 14.6233 14.5287 13.1667 13.7907C11.71 13.0527 10.4247 12.0168 9.39414 10.7502C8.87033 11.6533 8.71005 12.722 8.94586 13.739C9.18167 14.7561 9.79589 15.6451 10.6637 16.2256C10.0122 16.2049 9.37498 16.0295 8.80469 15.7139V15.7647C8.8041 16.7124 9.13175 17.6311 9.73192 18.3646C10.3321 19.0981 11.1678 19.6011 12.0969 19.7881C11.4934 19.9532 10.86 19.9773 10.2457 19.8584C10.5079 20.6735 11.018 21.3864 11.7048 21.8976C12.3916 22.4088 13.2209 22.6928 14.077 22.71C12.6237 23.8515 10.8285 24.4707 8.98047 24.4678C8.65274 24.4673 8.32533 24.4472 8 24.4076C9.87738 25.6121 12.0613 26.2518 14.2918 26.2506Z"
        fill={isHover ? '#00ACEE' : '#FCFCFC'}
      />
    </svg>
  );
}

TwitterIcon.propTypes = {
  size: oneOfType([number, string]),
};

export default TwitterIcon;
