import {
  API_TRANSACTION,
} from 'api/endpoints';
import {
  get,
  post,
} from 'libs/axios';

async function postTransaction({ data }) {
  const res = await post({
    url: API_TRANSACTION,
    data,
  });

  return res;
}

async function getTransactionBySignature({ signature }) {
  const res = await get({
    url: `${API_TRANSACTION}/signature/${signature}`,
  });

  return res;
}

async function postTransactionsBySignatures({ signatures }) {
  const res = await post({
    url: `${API_TRANSACTION}/signatures`,
    data: {
      signatures,
    },
  });

  return res;
}

async function postTransactionsByHashes({ hashes }) {
  const res = await post({
    url: `${API_TRANSACTION}/hashes`,
    data: {
      hashes,
    },
  });

  return res;
}

async function getTransactionById({ id }) {
  const res = await get({
    url: `${API_TRANSACTION}/${id}`,
  });

  return res;
}

const funcs = {
  postTransaction,
  getTransactionBySignature,
  postTransactionsBySignatures,
  postTransactionsByHashes,
  getTransactionById,
};

export default funcs;
