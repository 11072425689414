import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { animated, useTransition } from 'react-spring';
import { func } from 'prop-types';
import { useCallback, useState } from 'react';
import Link from 'next/link';
import mixpanel from 'libs/mixpanel';

// components
import DiscordIcon from 'components/icons/discord-icon';
import ExternalLink from 'components/external-link';
import MediumIcon from 'components/icons/medium-icon';
import TelegramIcon from 'components/icons/telegram-icon';
import TwitterIcon from 'components/icons/twitter-icon';

function HeaderMobileMenuTrigger({
  onMenuChange,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const bubbleTransition = useTransition(isOpen, {
    from: { x: -450, y: -450, scale: 0 },
    enter: { x: -450, y: -450, scale: 1 },
    leave: { x: -450, y: -450, scale: 0 },
    reverse: isOpen,
    config: {
      tension: 256,
    },
  });
  const menuTransition = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: isOpen,
    config: {
      tension: 256,
    },
  });

  const handleMenuOpen = useCallback(() => {
    setIsOpen(true);
    onMenuChange(true);
  }, [onMenuChange]);

  const handleMenuClose = useCallback(() => {
    setIsOpen(false);
    onMenuChange(false);
  }, [onMenuChange]);

  return (
    <>
      <button
        type="button"
        className="ml-auto p-2 text-white"
        onClick={handleMenuOpen}
      >
        <Bars3Icon width={24} height={24} />
      </button>
      {
        menuTransition((styles, item) => item && (
          <animated.div
            style={styles}
            className="absolute top-0 left-0 text-white w-full h-screen flex flex-col overflow-x-hidden overflow-y-scroll bg-black z-10"
          >
            <div className="relative">
              {
                bubbleTransition((bubbleStyles, bubble) => bubble && (
                  <animated.div
                    style={bubbleStyles}
                    className="absolute w-[1000px] h-[1000px] bg-neutral-700 rounded-full z-10"
                  />
                ))
              }
            </div>

            <div className="flex py-2 mx-[4%] mb-10">
              <button
                type="button"
                className="ml-auto p-2 z-20"
                onClick={handleMenuClose}
              >
                <XMarkIcon width={24} height={24} />
              </button>
            </div>

            <div className="flex flex-col mx-[4%] z-20">
              <Link
                href="/"
                passHref
              >
                <button
                  type="button"
                  className="flex px-6 py-3 text-2xl font-bold"
                  onClick={() => {
                    handleMenuClose();
                    mixpanel.track('👆🏼Homepage', { location: 'mobile menu' });
                  }}
                >
                  Home
                </button>
              </Link>
              <button
                type="button"
                className="flex px-6 py-3 text-2xl font-bold"
                onClick={() => {
                  // HACK
                  document.getElementById('overview').scrollIntoView(true);
                  handleMenuClose();
                }}
              >
                About Lithium
              </button>
              <ExternalLink
                ignoreBaseStyle
                className="flex items-center gap-2 px-6 py-3 text-2xl text-white font-bold my-2"
                href="https://docs.lith.finance"
                onClick={() => mixpanel.track('👆🏼Docs', { location: 'mobile menu' })}
              >
                Docs
              </ExternalLink>
            </div>

            <div className="mt-auto mb-16 z-20">
              <div className="flex mx-[4%] text-white justify-around items-center mb-8 gap-2">
                <a
                  className="w-9 h-9 flex justify-center items-center text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/lithiumfinance"
                  onClick={() => mixpanel.track('👆🏼Socials', { platform: 'twitter', location: 'mobile menu' })}
                >
                  <TwitterIcon size={36} />
                </a>
                <a
                  className="w-9 h-9 flex justify-center items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://t.me/lithiumfinanceofficial"
                  onClick={() => mixpanel.track('👆🏼Socials', { platform: 'telegram', location: 'mobile menu' })}
                >
                  <TelegramIcon size={36} />
                </a>
                <a
                  className="w-9 h-9 flex justify-center items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://medium.com/lithiumfinance"
                  onClick={() => mixpanel.track('👆🏼Socials', { platform: 'medium', location: 'mobile menu' })}
                >
                  <MediumIcon wsize={36} />
                </a>
                <a
                  className="w-9 h-9 flex justify-center items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://discord.gg/lithiumfinanceofficial"
                  onClick={() => mixpanel.track('👆🏼Socials', { platform: 'discord', location: 'mobile menu' })}
                >
                  <DiscordIcon size={36} />
                </a>
              </div>
              <div className="text-center">
                Visit on desktop for full experience.
              </div>
            </div>
          </animated.div>
        ))
      }
    </>
  );
}

HeaderMobileMenuTrigger.propTypes = {
  onMenuChange: func,
};

export default HeaderMobileMenuTrigger;
