import { animated, useSpring } from 'react-spring';
import { node, string } from 'prop-types';
import _ from 'lodash';

import Cross from 'components/icons/svgs/cross.svg';
import Tick from 'components/icons/svgs/tick.svg';

import styles from 'components/icons/loading-circle-icon.module.css';

function LoadingCircleStatus({ type, children }) {
  const normalBorderColor = {
    success: '#31A327FF',
    failed: '#910D13FF',
    cancelled: '#910D13FF',
    pending: '#00000000',
  };

  const baseBorderStyles = useSpring({
    opacity: _.isNil(type) ? 0 : 1,
  });
  const loadingBorderStyles = useSpring({
    strokeOpacity: type === 'pending' ? 1 : 0,
    strokeDasharray: type === 'pending' ? 85 : 112,
  });
  const normalBorderStyles = useSpring({
    strokeOpacity: type === 'pending' ? 0 : 1,
    strokeDasharray: type === 'pending' ? 85 : 112,
    stroke: normalBorderColor[type] ?? '#00000000',
  });
  const successIconStyles = useSpring({
    opacity: type === 'success' ? 1 : 0,
  });
  const failedIconStyles = useSpring({
    opacity: (type === 'failed' || type === 'cancelled') ? 1 : 0,
  });

  return (
    <div
      className="relative z-10"
    >
      <animated.svg
        viewBox="0 0 40 40"
        className={styles.svg}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Base white stroke background */}
        <animated.circle
          cx="20"
          cy="20"
          r="18"
          fillOpacity="0"
          stroke="#FCFCFC"
          strokeOpacity=".5"
          strokeWidth="3"
          strokeLinecap="round"
          style={baseBorderStyles}
        />
        {/* Border outline */}
        <animated.circle
          cx="20"
          cy="20"
          r="18"
          fillOpacity="0"
          stroke="white"
          strokeWidth="2"
          style={baseBorderStyles}
        />
        {/* Gradient loading border */}
        <animated.circle
          cx="20"
          cy="20"
          r="18"
          fillOpacity="0"
          stroke="url(#a)"
          strokeWidth={2}
          strokeLinecap="round"
          style={loadingBorderStyles}
        />
        {/* Single color border */}
        <animated.circle
          cx="20"
          cy="20"
          r="18"
          fillOpacity="0"
          strokeWidth={2}
          strokeLinecap="round"
          style={normalBorderStyles}
        />
        <defs>
          <linearGradient
            id="a"
            x1="2.243"
            y1="32.304"
            x2="43.924"
            y2="13.812"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CF3D90" />
            <stop offset=".328" stopColor="#E12E41" />
            <stop offset=".526" stopColor="#F04833" />
            <stop offset="1" stopColor="#EC8344" />
          </linearGradient>
        </defs>
      </animated.svg>

      {/* Success Circle */}
      <animated.div className="absolute p-0.5 z-10" style={successIconStyles}>
        <Tick width="100%" height="100%" />
      </animated.div>

      {/* Success Circle */}
      <animated.div className="absolute p-0.5 z-10" style={failedIconStyles}>
        <Cross width="100%" height="100%" />
      </animated.div>
      {children}
    </div>
  );
}

LoadingCircleStatus.propTypes = {
  children: node,
  type: string,
};

export default LoadingCircleStatus;
