import { API_NOTIFICATION_SETTING, API_USERS } from 'api/endpoints';
import { get, patch } from 'libs/axios';

async function getUserNotificationSettings({ userId, medium }) {
  const res = await get({
    url: `${API_USERS}/${userId}/notification-settings`,
    params: {
      medium,
    },
  });

  return res;
}

async function patchNotificationSetting({ id, data }) {
  const res = await patch({
    url: `${API_NOTIFICATION_SETTING}/${id}`,
    data,
  });

  return res;
}

const funcs = {
  getUserNotificationSettings,
  patchNotificationSetting,
};

export default funcs;
