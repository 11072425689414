import { message } from 'antd';
import axios from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

async function base({ method, url, params, data }) {
  let res;
  try {
    res = await axios.request({
      method,
      url,
      baseURL: publicRuntimeConfig.serverUrl,
      params,
      data,
      withCredentials: true,
    });

    if (res.status >= 200 && res.status < 300) {
      return {
        data: res.data.data,
        message: res.data.message,
        metadata: res.data.metadata,
        headers: res.headers,
      };
    }
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        // only failed unauthorized request allowed
        if (url !== '/api/users/current') {
          message.error('Please connect');

          const searchParams = new URLSearchParams();
          searchParams.append('redirection-path', window.location.pathname);

          window.location.replace(`/app/connect?${searchParams.toString()}`);
        }

        throw err;
      }

      if (err.response.status === 503) {
        // maintenance
        // eslint-disable-next-line consistent-return
        return;
      }

      if (err.response.status === 404) {
        return {
          isError: true,
          data: err.response.data.data,
          message: err.response.data.message,
          headers: err.response.headers,
          statusCode: 404,
        };
      }

      if (err.response.status >= 400 && err.response.status < 500) {
        // client error
        const msg = err.response.data.message;
        message.error(`Sorry your request couldn't be processed: ${msg}`);
        return {
          isError: true,
          data: err.response.data.data,
          message: err.response.data.message,
          headers: err.response.headers,
        };
      }

      if (err.response.status >= 500 && err.response.status < 600) {
        // internal server error
        // please try agian later
        // log bugsnag
        message.error(
          "Sorry your request couldn't be processed; Please try again later",
        );
        throw new Error(err.response.data.message);
      }
      if (err.request) {
        console.error('No response error', err);
      } else {
        console.error(err);
      }
    }
  }

  return null;
}

/**
 * Axios GET
 *
 * @function get
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 */
export async function get({ url, params }) {
  return base({
    method: 'get',
    url,
    params,
  });
}

/**
 * Axios POST
 *
 * @function post
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 */
export async function post({ url, params, data }) {
  return base({
    method: 'post',
    url,
    params,
    data,
  });
}

/**
 * Axios DELETE
 *
 * @function del
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 */
export async function del({ url, params, data }) {
  return base({
    method: 'delete',
    url,
    params,
    data,
  });
}

/**
 * Axios PUT
 * Updates a resource entirely
 *
 * @function put
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 */
export async function put({ url, params, data }) {
  return base({
    method: 'put',
    url,
    params,
    data,
  });
}

/**
 * Axios PATCH
 * Updates a resource partially
 *
 * @function patch
 * @param {object} params
 * @param {string} params.url - /foo/bar
 * @param {object} params.params - query params
 * @param {object} params.data - data supplied to body of request
 */
export async function patch({ url, params, data }) {
  return base({
    method: 'patch',
    url,
    params,
    data,
  });
}
