/* eslint-disable @next/next/no-img-element */
import {
  Button,
  Layout,
} from 'antd';
import { element } from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import mixpanel from 'libs/mixpanel';

// components
import ExternalLink from 'components/external-link';
import HeaderMobileMenuTrigger from 'layouts/header-mobile-menu-trigger';
import HeaderSocialConnectControls from 'layouts/header-socials-connect-controls';
import UserFooter from 'layouts/footer';

// hooks
import WindowSizeContext from 'contexts/window-size';

const { Header, Content } = Layout;

function L({ children }) {
  const { xs, sm } = useContext(WindowSizeContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const router = useRouter();

  // HACK
  useEffect(() => {
    const layoutEl = document.getElementsByClassName('ant-layout')[0];

    layoutEl.scrollTop = 0;
  }, [router.pathname]);

  return (
    <Layout
      style={{
        minHeight: '-webkit-fill-available',
      }}
      className={`flex flex-col min-h-full h-full bg-transparent overflow-x-hidden ${isMenuOpen ? 'overflow-y-hidden' : 'overflow-y-scroll'}`}
    >
      <Header className="sticky top-0 px-[4%] h-20 flex flex-col items-center bg-[#1E2024] z-[100]">
        <div className="max-w-[1200px] py-2 flex items-center w-full h-full">
          <Link href="/" passHref>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <a
              className="flex mr-8"
              onClick={() => mixpanel.track('👆🏼HomeLogo')}
            >
              <img
                alt="logo"
                width={200}
                height={22}
                src="/static/images/lithium/lithium-mainnet-beta-banner-light.png"
              />
            </a>
          </Link>
          <Link
            href="#DAO"
            className="flex mr-8"
            onClick={() => mixpanel.track('👆Gov')}
          >
            <Button
              type="text"
              className="text-[#D2D3DD] hover:text-current p-0 mr-6"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gov
            </Button>
          </Link>
          <ExternalLink
            href="https://docs.lith.finance"
            type="text"
            className="text-[#D2D3DD] hover:text-current p-0 m-0 flex items-center"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => mixpanel.track('👆Docs')}
          >
            Docs
          </ExternalLink>
          {
            (xs || sm)
              ? <HeaderMobileMenuTrigger onMenuChange={setIsMenuOpen} />
              : <HeaderSocialConnectControls showConnect={false} />
          }
        </div>
      </Header>
      <Content className="flex-grow flex-shrink-0 flex flex-col bg-black">
        {children}
      </Content>
      <UserFooter />
    </Layout>
  );
}

L.propTypes = {
  children: element.isRequired,
};

export default L;
