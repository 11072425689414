import { ASSET_TOP } from 'api/endpoints';
import { get } from 'libs/axios';

/**
 * Get collection mapping
 * @param {object} param
 * @returns {object}
 */
async function getCollectionMapping() {
  const res = await get({
    url: 'collections',
  });

  return res;
}

/**
 * Get collection token history
 * @param {object} param
 * @param {string} param.slug
 * @param {string} param.tokenId
 * @returns {object}
 */
async function getTokenHistory({ slug, tokenId }) {
  const res = await get({
    url: `collections/${slug}/tokens/${tokenId}`,
  });

  return res;
}

/**
 * Get collection latest quest
 * @param {object} param
 * @returns {object}
 */
async function getCollectionLatestQuests() {
  const res = await get({
    url: 'collections/latest-quests',
  });

  return res;
}

/**
 * Get highest valued nft
 *
 * @param {object} param
 * @param {string} param.collectionId
 * @returns {object}
 */
async function getTopAsset({ collectionId }) {
  const res = await get({
    url: `collections/${collectionId}${ASSET_TOP}`,
  });

  return res;
}

/**
 * Get collection priced history
 *
 * @param {object} param
 * @param {string} param.slug
 * @returns {object}
 */
async function getCollectionPricedHistory({ slug }) {
  const res = await get({
    url: `collections/${slug}/priced-history`,
  });

  return res;
}

/**
 * Get user pricing history
 *
 * @param {object} param
 * @param {string} param.userId
 * @param {string} param.slug
 * @param {string} param.questId
 * @returns {object}
 */
async function getUserPricingHistory({ userId, slug, questId }) {
  const res = await get({
    url: `api/collections/${slug}/user-pricing-history/users/${userId}/quests/${questId}`,
  });

  return res;
}

const funcs = {
  getCollectionMapping,
  getTokenHistory,
  getCollectionLatestQuests,
  getTopAsset,
  getCollectionPricedHistory,
  getUserPricingHistory,
};

export default funcs;
