/* eslint-disable no-else-return */
import { RetryableError, retry } from 'utils/retry';
import {
  func,
  shape,
  string,
} from 'prop-types';
import { useCallback, useContext, useEffect } from 'react';
import ContractsContext from 'contexts/contracts';

import useBlockNumber, { useFastForwardBlockNumber } from 'hooks/use-block-number';

export function shouldCheck(lastBlockNumber, tx) {
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / (60 * 1000);
  if (minutesPending > 60) {
    // every 10 blocks if pending longer than an hour
    return blocksSinceCheck > 9;
  } else if (minutesPending > 5) {
    // every 3 blocks if pending longer than 5 minutes
    return blocksSinceCheck > 2;
  } else {
    // otherwise every block
    return true;
  }
}

export default function Updater({ pendingTransactions, onCheck, onReceipt }) {
  const {
    provider,
  } = useContext(ContractsContext);

  const lastBlockNumber = useBlockNumber();
  const fastForwardBlockNumber = useFastForwardBlockNumber();

  const getReceipt = useCallback(
    (hash) => {
      if (!provider) throw new Error('No provider');
      const retryOptions = { n: 1, minWait: 50, maxWait: 100 };

      return retry(
        () =>
          provider.getTransactionReceipt(hash).then((receipt) => {
            if (receipt === null) {
              console.debug(`Retrying transaction receipt for ${hash}`);
              throw new RetryableError();
            }
            return receipt;
          }).catch((err) => {
            throw err;
          }),
        retryOptions,
      );
    },
    [provider],
  );

  useEffect(() => {
    if (!provider) return null;

    const cancels = Object.keys(pendingTransactions)
      .filter((hash) => shouldCheck(lastBlockNumber, pendingTransactions[hash]))
      .map((hash) => {
        const { promise, cancel } = getReceipt(hash);
        promise
          .then((receipt) => {
            if (receipt) {
              const { questId, questSubmissionId } = pendingTransactions[hash];
              onReceipt({ hash, receipt, questId, questSubmissionId });
            } else {
              onCheck({ hash, blockNumber: lastBlockNumber });
            }
          })
          .catch((error) => {
            if (!error.isCancelledError) {
              console.warn(`Failed to get transaction receipt for ${hash}`, error);
            }
          });
        return cancel;
      });

    return () => cancels.forEach((cancel) => cancel());
  }, [
    lastBlockNumber,
    getReceipt,
    fastForwardBlockNumber,
    onReceipt,
    onCheck,
    pendingTransactions,
    provider,
  ]);

  return null;
}

Updater.propTypes = {
  pendingTransactions: shape({
    questSubmissionId: string,
    questId: string,
  }),
  onCheck: func,
  onReceipt: func,
};
