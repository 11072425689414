import _ from 'lodash';
import useTransactionStore from 'stores/transaction-store';
import useUserQuery from 'hooks/queries/use-user-query';

function usePublicAddressSelector(transactionSelector) {
  const { user, isLoggedIn } = useUserQuery();

  // TODO: Use memo if necessary
  return useTransactionStore(
    isLoggedIn
      ? (state) => transactionSelector(state.transactions[user.publicAddress] ?? {})
      : () => ({}),
  );
}

export function useTransaction() {
  return usePublicAddressSelector(
    (transactions) => (transactions),
  );
}

export function usePendingTransaction() {
  return usePublicAddressSelector(
    (transactions) =>
      _.pickBy(
        transactions,
        (t) => (t.status === 'pending'),
      ),
  );
}

export function useHasPendingTransaction() {
  return !_.isEmpty(usePendingTransaction());
}

export function useUnseenTransaction() {
  return usePublicAddressSelector(
    (transactions) =>
      _.pickBy(
        transactions,
        (t) => (t.hasUpdate),
      ),
  );
}

export function useQuestSubmissionPendingTransaction() {
  return usePublicAddressSelector(
    (transactions) =>
      _.pickBy(
        transactions,
        (t) =>
          t.status === 'pending'
        && [
          'commitAnswer',
          'updateAnswer',
          'withdrawAnswer',
          'claimReward',
          'refundAnswer',
        ].includes(t.action),
      ),
  );
}

export function useEnquiryPendingTransaction() {
  return usePublicAddressSelector(
    (transactions) =>
      _.pickBy(
        transactions,
        (t) =>
          t.status === 'pending'
          && ['createEnquiry'].includes(t.action),
      ),
  );
}

export function useClaimTokenPendingTransaction() {
  return usePublicAddressSelector(
    (transactions) =>
      _.pickBy(
        transactions,
        (t) =>
          t.status === 'pending'
          && ['faucet', 'airdrop'].includes(t.action),
      ),
  );
}
