import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { bool, shape, string } from 'prop-types';
import { chooseExplorer } from 'smart-contracts';
import { useCallback, useEffect, useState } from 'react';

import ExternalLink from 'components/external-link';
import LoadingCircleStatus from 'components/loading-circle-status';

import { useMarkTransactionAsSeen, useUpdateTransactionStatus } from 'stores/actions/transaction-actions';
import useIntersectionObserver from 'hooks/use-intersection-observer';

function RecentTransactionsListItem({ transaction, parentRef }) {
  const { containerRef, isIntersect } = useIntersectionObserver({
    root: parentRef,
    threshold: 0.8,
  });
  const [hasUpdate, setHasUpdate] = useState(transaction.hasUpdate);

  const markAsSeen = useMarkTransactionAsSeen();
  const updateStatus = useUpdateTransactionStatus();

  useEffect(() => {
    if (isIntersect && transaction.hasUpdate) {
      markAsSeen({ hash: transaction.transaction.hash });
    }
    return () => { setHasUpdate(false); };
  }, [
    isIntersect,
    markAsSeen,
    transaction.hasUpdate,
    transaction?.transaction?.hash,
  ]);

  const confirmCancel = useCallback(() => {
    Modal.confirm({
      title: <span className="text-base font-bold">Have you cancelled the transaction on MetaMask?</span>,
      icon: <ExclamationCircleOutlined />,
      cancelButtonProps: {
        ghost: true,
        size: 'small',
      },
      cancelText: 'Not Yet, Discard Cancel',
      okButtonProps: {
        size: 'small',
      },
      okText: 'Yes, It’s Cancelled',
      onOk: () => updateStatus({ hash: transaction.transaction.hash, status: 'cancelled' }),
    });
  }, [transaction?.transaction?.hash, updateStatus]);

  const cancel = useCallback(() => {
    Modal.confirm({
      title: <span className="text-base font-bold">Confirm to Proceed?</span>,
      icon: <ExclamationCircleOutlined />,
      closable: true,
      closeIcon: <CloseOutlined className="text-black" />,
      content: (
        <>
          <div className="text-sm">
            Please make sure you have cancelled the transaction on MetaMask.
          </div>
          <div className="bg-[#FAAD141A] mt-2 text-sm font-bold p-[10px] text-center">
            <span className="text-[#F5222D]">DO NO PROCEED UNLESS</span>
            <br />
            <span>CANCELLED ON METAMASK</span>
          </div>
        </>
      ),
      cancelButtonProps: {
        ghost: true,
        size: 'small',
        className: 'px-4',
      },
      cancelText: 'Confirm Proceed',
      onCancel: (close) => {
        if (!close.triggerCancel) {
          confirmCancel();
          close(false);
        }
      },
      okButtonProps: {
        size: 'small',
        className: 'px-4',
      },
      okText: <ExternalLink href={`${chooseExplorer()}/tx/${1}`} iconSize={18}>View on Explore</ExternalLink>,
      onOk: () => true,
    });
  }, [confirmCancel]);

  const iconLookup = {
    pending: (
      <LoadingCircleStatus type="pending">
        <div className="w-5 h-5" />
      </LoadingCircleStatus>
    ),
    success: (
      <CheckCircleIcon
        height={20}
        width={20}
        color="#52c41a"
      />
    ),
    failed: <XCircleIcon height={20} width={20} color="#f5222d" />,
    cancelled: <XCircleIcon height={20} width={20} color="#f5222d" />,
  };

  const statusLookup = {
    pending: <div className="text-[10px]">Pending</div>,
    success: <div className="text-[10px] text-[#31A327]">Success</div>,
    failed: <div className="text-[10px] text-[#F5222D]">Error</div>,
    cancelled: <div className="text-[10px] text-[#F5222D]">Cancelled</div>,
  };

  return (
    <div className="flex justify-between" ref={containerRef}>
      <div className="flex items-center h-6">
        {iconLookup[transaction.status]}
        <div className="ml-3 flex items-center gap-3 h-5">
          <ExternalLink
            className="h-5 flex items-center text-sm font-bold text-white underline p-0 m-0"
            iconSize={18}
            href={`${chooseExplorer()}/tx/${transaction.transaction?.hash}`}
          >
            {transaction.name}
          </ExternalLink>
          {statusLookup[transaction.status]}
        </div>
        {hasUpdate && (
          <div className="bg-[#F5222D] ml-1.5 mt-2.5 w-1.5 h-1.5 rounded full" />
        )}
      </div>
      {
        transaction.status === 'pending' && (
          <button type="button" className="underline font-bold text-[10px]" onClick={cancel}>
            Cancel
          </button>
        )
      }
    </div>
  );
}

RecentTransactionsListItem.propTypes = {
  parentRef: shape({}),
  transaction: shape({
    transaction: shape({
      hash: string,
    }),
    name: string,
    status: string,
    hasUpdate: bool,
  }),
};

export default RecentTransactionsListItem;
