import {
  ANSWER,
  ASSET_TOP,
  LITH_BALANCE,
  MATIC_BALANCE,
  OPENSEA_COLLECTION,
  QUEST,
  QUESTS,
  QUESTS_PARTICIPATED,
  QUEST_ITEMS,
  QUEST_SUBMISSIONS_USER,
  RP_BALANCE,
  USER_SUBMISSION,
} from 'libs/react-query/constants';
import { message } from 'antd';
import { questSubmissionApi } from 'api/fetchers';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { wait } from 'utils';

import Updater from 'components/updater/updater';

// hooks
import { useQuestSubmissionPendingTransaction } from 'stores/selectors/transaction-selectors';
import { useUpdateTransactionLastChecked, useUpdateTransactionStatus } from 'stores/actions/transaction-actions';
import useUserQuery from 'hooks/queries/use-user-query';

function QuestSubmissionUpdater() {
  const { user } = useUserQuery();
  const queryClient = useQueryClient();

  const pendingTransactions = useQuestSubmissionPendingTransaction();
  const updateTransactionLastChecked = useUpdateTransactionLastChecked();
  const updateTransactionStatus = useUpdateTransactionStatus();

  const finalizeTransaction = useCallback(async (args) => {
    const {
      hash,
      receipt,
      questSubmissionId,
    } = args;

    updateTransactionStatus({ hash, status: receipt.status === 1 ? 'success' : 'failed' });

    const { action } = pendingTransactions[hash];

    // update balance when a transaction finalized
    queryClient.invalidateQueries([MATIC_BALANCE, user?.publicAddress]);
    queryClient.invalidateQueries([LITH_BALANCE, user?.publicAddress]);
    queryClient.invalidateQueries([RP_BALANCE, user?.publicAddress]);

    if (receipt.status === 1) {
      // wait for subgraph to be populated
      await wait(5000);
      if (action === 'commitAnswer') {
        message.success('Your answer has been submitted');
      } else if (action === 'updateAnswer') {
        message.success('Your answer has been updated');
      } else if (action === 'withdrawAnswer') {
        message.success('Your answer has been withdrawn');
        window.location.reload();
      } else if (action === 'refundAnswer') {
        message.success('You have successfully claimed the refund');
        window.location.reload();
      } else if (action === 'claimReward') {
        await questSubmissionApi.patchQuestSubmission({
          id: questSubmissionId,
          data: { status: 'CLAIMED', claimedAt: new Date().toISOString() },
        });
        message.success('Your reward has been claimed');
        window.location.reload();
      }

      // update quest data
      queryClient.invalidateQueries([QUEST]);
      queryClient.invalidateQueries([QUESTS]);
      queryClient.invalidateQueries([OPENSEA_COLLECTION]);
      queryClient.invalidateQueries([ASSET_TOP]);
      queryClient.invalidateQueries([USER_SUBMISSION]);
      queryClient.invalidateQueries([QUEST_ITEMS]);

      // update my submission data
      queryClient.invalidateQueries([QUESTS_PARTICIPATED]);
      queryClient.invalidateQueries([QUEST_SUBMISSIONS_USER]);
      queryClient.invalidateQueries([ANSWER]);
    }
  }, [
    pendingTransactions,
    queryClient,
    user?.publicAddress,
    updateTransactionStatus,
  ]);

  return (
    <Updater
      pendingTransactions={pendingTransactions}
      onReceipt={finalizeTransaction}
      onCheck={updateTransactionLastChecked}
    />
  );
}

QuestSubmissionUpdater.propTypes = {};

export default QuestSubmissionUpdater;
