import { gql } from 'graphql-request';
import { subgraphClient } from 'libs/graphql-client';

/**
 * Get enquiry user answer
 *
 * @param {object} param
 * @param {string} param.contractAddress
 * @param {string} param.externalId
 * @param {string} param.publicAddress
 * @returns {Promise<object>}
 */
export async function getEnquiryUserAnswer({ contractAddress, externalId, publicAddress }) {
  const query = gql`
    query($id: String!) {
      enquiryUserAnswer(
        id: $id
      ) {
        id
        expert
        latestAnswer {
          claimedAt
          createdAt
          expert
          id
          refundedAt
          rewardAfterFee
          rp
          rpRefund
          rpReward
          signature
          stakeAmount
          stakeAmountAfterFee
          stakeAmountRefund
          stakeToken
          status
          updatedAt
          withdrawnAt
          actionLogs(orderBy: timestamp, orderDirection: desc) {
            hash
            status
          }
        }
      }
    }
  `;
  const res = await subgraphClient.request(
    query,
    {
      id: `${contractAddress.toLowerCase()}-${externalId}-${publicAddress}`,
    },
  );

  return res?.enquiryUserAnswer?.latestAnswer;
}

/**
 * Get enquiries user answers lookup
 *
 * @param {object} param
 * @param {object[]} param.enquiries
 * @param {string} param.enquiries[].contractAddress
 * @param {string} param.enquiries[].externalId
 * @param {string} param.publicAddress
 * @returns {Promise<object>}
 */
export async function getEnquiryUserAnswers({ enquiries, publicAddress }) {
  const query = gql`
    query($ids: [String!]!) {
      enquiryUserAnswers(
        where: {
          id_in: $ids
        }
      ) {
        id
        expert
        latestAnswer {
          actionLogs(orderBy: timestamp, orderDirection: desc) {
            hash
            status
          }
          id
          status
          rp
          claimedAt
          createdAt
          refundedAt
          rpRefund
          rpReward
          signature
          stakeAmount
          withdrawnAt
          rewardAfterFee
          stakeAmountAfterFee
          stakeAmountRefund
        }
        enquiry {
          expertCount
        }
      }
    }
  `;
  const res = await subgraphClient.request(
    query,
    {
      ids: enquiries.map((e) => (
        `${e.contractAddress.toLowerCase()}-${e.externalId}-${publicAddress}`
      )),
    },
  );

  return res.enquiryUserAnswers;
}

export async function getSubgraphParticipatedEnquiryIdsByUser({ publicAddress, first, skip }) {
  const query = gql`
    query($publicAddress: String!, $first: Int!, $skip: Int!) {
      answerIds: answers (
        where: {
          expert: $publicAddress,
          status_in: [CREATED, UPDATED, CLAIMED]
        }
      ) {
        id
      }
      answers(
        first: $first
        skip: $skip
        orderDirection: desc
        orderBy: createdAt
        where: {
          expert: $publicAddress,
          status_in: [CREATED, UPDATED, CLAIMED]
        }
      ) {
        id
        expert
        signature
        enquiryUserAnswer {
          enquiry {
            id
            status
            refundedAt
            enquiryId
          }
        }
      }
    }
  `;
  const res = await subgraphClient.request(
    query,
    {
      publicAddress,
      first,
      skip,
    },
  );

  return {
    total: res?.answerIds.length,
    enquiries: res?.answers.map((answer) => (answer.enquiryUserAnswer.enquiry)),
    enquiryIds: res?.answers.map((answer) => (answer.enquiryUserAnswer.enquiry.id)),
    signatures: res?.answers.map((answer) => (answer.signature)),
  };
}

/**
 * Get supplementary data from subgraph for an enquiry
 *
 * @param {object} param
 * @param {string} param.contractAddress
 * @param {string} param.externalId
 * @returns {Promise<object>}
 */
export async function getSubgraphEnquiry({ contractAddress, externalId }) {
  const query = gql`
    query($id: String!) {
      enquiry(id: $id) {
        expertCount
      }
    }
  `;
  const res = await subgraphClient.request(
    query,
    {
      id: `${contractAddress.toLowerCase()}-${externalId}`,
    },
  );

  return res.enquiry;
}

/**
 * Get supplementary data from subgraph for an enquiry
 *
 * @param {object} param
 * @param {object[]} param.enquiries
 * @param {string} param.enquiries[].contractAddress
 * @param {string} param.enquiries[].externalId
 * @returns {Promise<object>}
 */
export async function getSubgraphEnquiries({ enquiries }) {
  const query = gql`
    query($ids: [String!]!) {
      enquiries(where: {
        id_in: $ids
      }) {
        id
        expertCount
        enquiryId
        status
      }
    }
  `;

  const res = await subgraphClient.request(
    query,
    {
      ids: enquiries.map((e) => `${e.contractAddress.toLowerCase()}-${e.externalId}`),
    },
  );

  return res.enquiries;
}
