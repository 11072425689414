import { dayjs } from 'libs/dayjs';
import { persist } from 'zustand/middleware';
import _ from 'lodash';
import create from 'zustand';
import produce from 'immer';

const logger = (config) => (set, get, api) =>
  config(
    (...args) => {
      console.debug('Applying', args);
      set(...args);
      console.debug('Next State', get());
    },
    get,
    api,
  );

const useTransactionStore = create(
  persist(
    logger(
      (set) => ({
        transactions: {},
        add: ({ publicAddress, transaction, hash, ...values }) =>
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);
              newState.transactions[publicAddress] ??= {};
              newState.transactions[publicAddress][hash] = {
                timestamp: dayjs().unix(),
                transaction: {
                  ...transaction,
                },
                status: 'pending',
                hasUpdate: true,
                ...values,
              };
              return newState;
            }),
          ),
        updateLastChecked: ({ publicAddress, hash, blockNumber }) =>
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);
              newState.transactions[publicAddress][hash].lastCheckedBlockNumber = blockNumber;
              return newState;
            }),
          ),
        updateStatus: ({ publicAddress, hash, status }) =>
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);
              newState.transactions[publicAddress][hash].status = status;
              newState.transactions[publicAddress][hash].hasUpdate = true;
              return newState;
            }),
          ),
        markAsSeen: ({ publicAddress, hash }) =>
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);
              newState.transactions[publicAddress][hash].hasUpdate = false;
              return newState;
            }),
          ),
        clearFinished: ({ publicAddress }) =>
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);
              newState.transactions[publicAddress] ??= {};

              Object.keys(newState.transactions?.[publicAddress]).forEach((hash) => {
                if (newState.transactions[publicAddress][hash].status !== 'pending') {
                  delete newState.transactions[publicAddress][hash];
                }
              });

              return newState;
            }),
          ),
        delete: ({ publicAddress, hash }) =>
          set(
            produce((state) => {
              const newState = _.cloneDeep(state);
              delete newState.transactions[publicAddress][hash];
              return newState;
            }),
          ),
      }),
    ),
    { name: 'transactions' },
  ),
);

export default useTransactionStore;
