import { DATE_TIME_FORMAT, dayjs } from 'libs/dayjs';
import BigNumber from 'libs/bignumber';
import numbro from 'numbro';
import seedrandom from 'seedrandom';

/**
 * Convert a string to hexidecimal
 *
 * @function toHex
 * @param {string} string
 * @returns {string} hexidecimal string
 */
export function toHex(string) {
  return string
    .split('')
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('');
}

/**
 * Shorten a string
 * @param {string} address
 * @param {number} [headLength=5]
 * @param {number} [tailLength=5]
 * @returns {string} shortened address
 */
export function shortenString(
  str,
  headLength = 5,
  tailLength = 5,
) {
  return `${str.substring(0, headLength)}...${str.substring(
    str.length - tailLength,
  )}`;
}

/**
 * Convert a id to a shortened version
 * @param {string} id
 * @param {number} [headLength=4]
 * @param {number} [tailLength=3]
 * @returns {string} shortened id
 */
export function toShortenedId(
  id,
  headLength = 4,
  tailLength = 3,
) {
  return `${id.substring(0, headLength)}...${id.substring(
    id.length - tailLength,
  )}`;
}

/**
 * Check if a string is a valid uuid
 *
 * @function isUuid
 * @param {string} string
 * @returns {Boolean}
 */
export function isUuid(string) {
  return !!string.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  );
}

/**
 * Check if a string = "true"
 *
 * @function isStringTrue
 * @param {string} string
 * @returns {Boolean}
 */
export function isStringTrue(string) {
  return string === true || string === 'true';
}

/**
 * Check if a string = "false"
 *
 * @function isStringFalse
 * @param {string} string
 * @returns {Boolean}
 */
export function isStringFalse(string) {
  return string === false || string === 'false';
}

/**
 * Returns a render function for admin table
 *
 * @param {string} dataIndex
 * @returns {Function}
 */
export function getDateTimeRenderFunc(dataIndex) {
  return function render(_, record) {
    return record[dataIndex] ? dayjs(record[dataIndex]).utc().format(DATE_TIME_FORMAT) : '';
  };
}

export function findContractEvent(receipt, eventName) {
  const event = receipt.events.find((e) => e.event === eventName);
  if (event) {
    return event;
  }
  throw new Error('Event not found');
}

export function formatNumbro(value) {
  if (!BigNumber(value).isNaN()) {
    return numbro(value).format({
      thousandSeparated: true,
      mantissa: 2,
      trimMantissa: true,
    });
  }

  return value;
}

export function formatTokenNumbro(value) {
  // show 4 decimals if less than 1
  if (!BigNumber(value).isNaN()) {
    if (BigNumber(value).gte(1)) {
      return numbro(value).format({
        thousandSeparated: true,
        mantissa: 2,
      });
    }
    return numbro(value).format({
      thousandSeparated: true,
      mantissa: 4,
      trimMantissa: true,
    });
  }
  return value;
}

export function wait(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitRandom(min, max) {
  return wait(min + Math.round(Math.random() * Math.max(0, max - min)));
}

export function shuffle(array, seed) {
  let currentIndex = array.length; let
    randomIndex;
  const seededRng = seed ? seedrandom(seed) : Math.random;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(seededRng() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    // eslint-disable-next-line no-param-reassign
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function chooseChainExplorer({ chain, type, destination }) {
  switch (chain) {
    case 'SOLANA':
      switch (type) {
        case 'ADDRESS':
          return `https://solscan.io/account/${destination}`;
        default:
          return `https://solscan.io/tx/${destination}`;
      }
    default:
      switch (type) {
        case 'ADDRESS':
          return `https://etherscan.io/address/${destination}`;
        default:
          return `https://etherscan.io/tx/${destination}`;
      }
  }
}
