import {
  LITH_BALANCE,
  RP_AIRDROP_CLAIMED,
  RP_BALANCE,
  RP_CLAIMED,
} from 'libs/react-query/constants';
import { ethers } from 'ethers';
import { message } from 'antd';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { userApi } from 'api/fetchers';
import mixpanel from 'libs/mixpanel';

import Updater from 'components/updater/updater';

// hooks
import { useClaimTokenPendingTransaction } from 'stores/selectors/transaction-selectors';
import { useUpdateTransactionLastChecked, useUpdateTransactionStatus } from 'stores/actions/transaction-actions';
import useUserQuery from 'hooks/queries/use-user-query';

function ClaimTokenUpdater() {
  const { user } = useUserQuery();
  const queryClient = useQueryClient();

  const pendingTransactions = useClaimTokenPendingTransaction();
  const updateTransactionLastChecked = useUpdateTransactionLastChecked();
  const updateTransactionStatus = useUpdateTransactionStatus();
  const { mutateAsync: patchUserMutateAsync } = useMutation(userApi.patchUser);

  const finalizeTransaction = useCallback(({ hash, receipt }) => {
    const { value, contract } = pendingTransactions[hash];

    updateTransactionStatus({ hash, status: receipt.status === 1 ? 'success' : 'failed' });

    if (contract === 'LithiumToken') {
      queryClient.invalidateQueries([LITH_BALANCE, user?.publicAddress]);

      if (receipt.status === 1) {
        mixpanel.track('✅Claim TestLith');

        message.destroy('claim-lith');
        message.success(
          `You've successfully claimed ${ethers.utils.formatEther(
            value,
          )} LITH tokens.`,
        );
      } else {
        mixpanel.track('❌Claim TestLith');

        message.destroy('claim-lith');
        message.error('Your transaction is reverted on chain');
      }
    }

    if (contract === 'Reputation') {
      queryClient.invalidateQueries([RP_BALANCE, user?.publicAddress]);
      queryClient.invalidateQueries([RP_CLAIMED, user?.publicAddress]);
      queryClient.invalidateQueries([RP_AIRDROP_CLAIMED, user?.publicAddress]);

      if (receipt.status === 1) {
        mixpanel.track('✅Claim RP airdrop');

        patchUserMutateAsync({
          id: user.id,
          data: {
            rpAirdropClaimedAt: new Date().toISOString(),
          },
        }).then(() => {
          message.success(`You've successfully claimed ${ethers.utils.formatUnits(value, 8)} RP.`);
        });
      } else {
        mixpanel.track('❌Claim RP airdrop');
        message.error('Your transaction is reverted on chain');
      }
      message.destroy('claim-rp');
      message.destroy('claim-rp-airdrop');
    }
  }, [
    pendingTransactions,
    updateTransactionStatus,
    queryClient,
    user?.publicAddress,
    user?.id,
    patchUserMutateAsync,
  ]);

  return (
    <Updater
      pendingTransactions={pendingTransactions}
      onReceipt={finalizeTransaction}
      onCheck={updateTransactionLastChecked}
    />
  );
}

ClaimTokenUpdater.propTypes = {};

export default ClaimTokenUpdater;
