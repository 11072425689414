import { RP_BALANCE } from 'libs/react-query/constants';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import BigNumber from 'bignumber.js';
import ContractsContext from 'contexts/contracts';
import useUserQuery from 'hooks/queries/use-user-query';

export default function useRpBalanceQuery(placeholderData) {
  const { user, isLoggedIn } = useUserQuery();

  const {
    micTokenReadContract,
  } = useContext(ContractsContext);

  const {
    data: balance,
    ...values
  } = useQuery(
    [RP_BALANCE, user?.publicAddress],
    () => micTokenReadContract.balanceOf(user.publicAddress),
    {
      placeholderData,
      enabled: Boolean(isLoggedIn && typeof window !== 'undefined' && window.ethereum),
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    rpBalance: balance && ethers.utils.formatUnits(balance, 8),
    rpBalanceBn: balance && BigNumber(ethers.utils.formatUnits(balance, 8)),
    ...values,
  };
}
